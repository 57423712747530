/**
 * @file GeneralInformation.tsx
 * @description This component represents the General Information page in the Medical History section.
 * It allows users to view and update their personal information.
 */

import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import {
  getGeneralInfo,
  updateGeneralInfo,
} from "common/src/utility/axioscalls";
import LoadingAnimation from "../../components/LoadingAnimation";
import { Country, State } from "country-state-city";
import useToast from "../../hooks/useToast";
import { FormGeneralInformationSchema } from "common/src/utility/validate/formValidation";
import { z } from "zod";
import { capitalizeFirstLetter } from "common/src/utility";
import Input from "../../components/Input";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import MainLogo from "../../components/MainLogo";
import CustomDropdown from "../../components/CustomDropDown";
import { GeneralInformationFormValues } from "common/src/types";

/**
 * GeneralInformation component
 * @returns {JSX.Element} The rendered GeneralInformation component
 */
const GeneralInformation: React.FC = () => {
  const { setNotify, setNotifyType } = useToast();
  const [formValue, setFormValue] = useState<GeneralInformationFormValues>({
    first_name: "",
    last_name: "",
    email: "",
    profile_pic: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    phone: "",
    birth_date: "",
    gender: "",
    hair_color: "",
    eye_color: "",
    height: null,
    weight: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [flag, setFlag] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const filteredCountries = allCountries.filter((country) =>
      ["US", "CA", "MX"].includes(country.isoCode)
    );

    setCountries(filteredCountries);
  }, []);

  useEffect(() => {
    /**
     * Fetches user's general information from the server
     */
    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await getGeneralInfo();

        if (res.code === 200) {
          setFirstName(res.data.general.first_name);
          setLastName(res.data.general.last_name);
          setFormValue({
            ...formValue,
            first_name: res.data.general.first_name,
            last_name: res.data.general.last_name,
            email: res.data.general.email,
            profile_pic: res.data.general.profile_pic,
            address1: res.data.general.address1,
            address2: res.data.general.address2,
            country: res.data.general.country,
            state: res.data.general.state,
            city: res.data.general.city,
            zipcode: res.data.general.zipcode,
            phone: res.data.general.phone,
            birth_date: res.data.general.birth_date,
            gender: res.data.general.gender,
            hair_color: res.data.general.hair_color,
            eye_color: res.data.general.eye_color,
            height: res.data.general.height,
            weight: res.data.general.weight,
          });

          const states = State.getStatesOfCountry(res.data.general.country);

          setFlag(res.data.general.country);
          setStates(states);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  /**
   * Handles input change events
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "height" || e.target.name === "weight") {
      setFormValue({
        ...formValue,
        [e.target.name]:
          e.target.value === "" ? null : parseInt(e.target.value),
      });
    } else {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }
  };

  /**
   * Handles the update of general information
   */
  const handleUpdate = async () => {
    setSaveLoading(true);

    let formData = { ...formValue };

    if (formData.height === null) {
      delete formData.height;
    }

    if (formData.weight === null) {
      delete formData.weight;
    }

    try {
      FormGeneralInformationSchema.parse(formData);

      const res = await updateGeneralInfo(formData);

      if (res.code === 200) {
        setNotifyType("success");
        setNotify("Updated the information successfully!");
      }
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error("Validation errors:", error.errors);
        setNotifyType("danger");

        setNotify(
          capitalizeFirstLetter(String(error.errors[0].path[0])) +
            ": " +
            error.errors[0].message
        );
      }
    } finally {
      setSaveLoading(false);
    }
  };

  /**
   * Handles country change events
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The change event
   */
  const handleChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValue({
      ...formValue,
      country: e.target.value,
    });

    const states = State.getStatesOfCountry(e.target.value);
    setStates(states);
  };

  /**
   * Handles state change events
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The change event
   */
  const handleChangeState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValue({
      ...formValue,
      state: e.target.value,
    });
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className="h-h-46 flex flex-col text-[#0D122A]">
            <div className="grow px-5">
              <MainLogo />

              <BackIcon />

              <RedPanel />

              <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
                <span>General</span>
                <span className="text-[#DC3E46]">Information</span>
              </p>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">First Name</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="first_name"
                    value={formValue.first_name}
                    onChange={onChange}
                    placeholder="Alberto"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Last Name</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="last_name"
                    value={formValue.last_name}
                    onChange={onChange}
                    placeholder="Alberto"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Phone number</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="phone"
                    value={formValue.phone}
                    onChange={onChange}
                    placeholder="123456789"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Address</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="address1"
                    value={formValue.address1}
                    onChange={onChange}
                    placeholder="address1"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="address2"
                    value={formValue.address2}
                    onChange={onChange}
                    placeholder="address2"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-base font-semibold mt-4">Country</p>

                <CustomDropdown
                  options={[
                    "Select a country",
                    ...countries.map((country) => country.name),
                  ]}
                  value={
                    countries.find((c) => c.isoCode === formValue.country)?.name
                  }
                  flag={formValue.country ? flag : ""}
                  onChange={(value) => {
                    const country = countries.find((c) => c.name === value);
                    if (country) {
                      setFlag(country.isoCode);
                      handleChangeCountry({
                        target: { value: country.isoCode },
                      } as React.ChangeEvent<HTMLSelectElement>);
                    } else {
                      setFlag("");
                      handleChangeCountry({
                        target: { value: "" },
                      } as React.ChangeEvent<HTMLSelectElement>);
                    }
                  }}
                  placeholder="Select a country"
                  isCountry={true}
                  countries={countries}
                  isBorder={false}
                />
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">State Name</p>

                <CustomDropdown
                  options={states.map((state) => state.name)}
                  value={
                    formValue.state
                      ? states.find((s) => s.isoCode === formValue.state)
                          ?.name || ""
                      : ""
                  }
                  onChange={(value) => {
                    const state = states.find((s) => s.name === value);
                    if (state) {
                      handleChangeState({
                        target: { value: state.isoCode },
                      } as React.ChangeEvent<HTMLSelectElement>);
                    }
                  }}
                  placeholder="Select a State"
                  isBorder={false}
                />
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">City</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="city"
                    value={formValue.city}
                    onChange={onChange}
                    placeholder="city"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Zip code</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="zipcode"
                    value={formValue.zipcode}
                    onChange={onChange}
                    placeholder="78967"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-base font-semibold mt-4">Gender</p>

                <CustomDropdown
                  options={[
                    "Male",
                    "Female",
                    "Non Binary",
                    "Other",
                    "Prefer not to say",
                  ]}
                  value={formValue.gender || ""}
                  onChange={(value) =>
                    onChange({
                      target: { name: "gender", value },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                  placeholder="Select a Gender"
                  isBorder={false}
                />
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Eye color</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="eye_color"
                    value={formValue.eye_color}
                    onChange={onChange}
                    placeholder="black"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Hair color</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="hair_color"
                    value={formValue.hair_color}
                    onChange={onChange}
                    placeholder="brown"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Height</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="height"
                    value={formValue.height}
                    onChange={onChange}
                    placeholder="180"
                    type="number"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Weight</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="weight"
                    value={formValue.weight}
                    onChange={onChange}
                    placeholder="80"
                    type="number"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className=" text-base font-semibold mt-4">Birthday</p>

                <div className="flex items-center justify-start bg-white py-3 rounded-[14px] outline-none ">
                  <Input
                    name="birth_date"
                    value={formValue.birth_date}
                    onChange={onChange}
                    placeholder="11 - June - 2000"
                    className="w-full bg-white rounded-[14px] outline-none text-base px-3"
                  />
                </div>
              </div>
            </div>

            <div className="px-5">
              <button
                className="w-full px-5 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-4 text-white text-lg my-4"
                onClick={handleUpdate}
              >
                {saveLoading ? (
                  <IonSpinner
                    name="crescent"
                    color="light"
                    style={{ fontSize: "30px" }}
                  />
                ) : (
                  <span>Save</span>
                )}
              </button>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default GeneralInformation;

import React from "react";
import { MedicationProps } from "./MedicationCard";
import { IonModal } from "@ionic/react";

export const MedicationViewModal: React.FC<{
  medication: MedicationProps;
  showMedicationModal: boolean;
  handleCloseMedicationModal: () => void;
}> = ({ medication, showMedicationModal, handleCloseMedicationModal }) => {
  return (
    <IonModal
      isOpen={showMedicationModal}
      id="custom-modal"
      onDidDismiss={handleCloseMedicationModal}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#0D122A]">
        <p className="w-full text-center space-x-2 text-2xl font-bold my-3 bg-ctgr-100">
          <span>Medication</span>
          <span className="text-[#DC3E46]">Details</span>
        </p>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Medication:</p>
          <p>{medication?.medication_name}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Dosage:</p>
          <p>{medication?.dosage}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Measurement:</p>
          <p>{medication?.measurement}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Frequency:</p>
          <p>{medication?.frequency}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Form:</p>
          <p>{medication?.form}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Doctor:</p>
          <p>{medication?.doctor}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Notes (optional):</p>
          <p>{medication?.notes}</p>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={handleCloseMedicationModal}
            className="w-fit px-3 py-2 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg z-10"
          >
            Close
          </button>
        </div>
      </div>
    </IonModal>
  );
};

export default MedicationViewModal;

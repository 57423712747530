import { createContext } from "react";

type RemoveModalContextProps = {
  showRemoveModal: boolean;
  setShowRemoveModal: (showRemoveModal: boolean) => void;
  actionType: string;
  setActionType: (actionType: string) => void;
};

const RemoveModalContext = createContext<RemoveModalContextProps | undefined>(
  undefined
);

export default RemoveModalContext;

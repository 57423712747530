import { IonCheckbox } from "@ionic/react";

interface MedicationViewProps {
  active: boolean;
  doctor: string;
  dosage: number | null;
  form: string;
  frequency: string;
  measurement: string;
  medication_name: string;
  notes: string;
}

const MedicationView: React.FC<{
  medication: MedicationViewProps;
}> = ({ medication }) => {
  return (
    <div className="w-full mt-6 text-[#0D122A] flex flex-col gap-6">
      <p className="text-base">
        {medication.medication_name} - {medication.dosage}
        {medication.measurement}/{medication.frequency}
      </p>

      <p className="text-base">{medication.doctor}</p>

      <p className="text-base">{medication.notes}</p>

      <div className="flex justify-start items-center mt-2 gap-2">
        <p className="text-base">Taking currently</p>

        <IonCheckbox
          labelPlacement="end"
          className="text-[#0D122A] text-base border border-gray-300"
          color="success"
          checked={medication.active}
        />
      </div>
    </div>
  );
};

export default MedicationView;

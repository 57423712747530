import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import Input from "../Input";
import { DoctorProps } from "common/src/types";
import { updateDoctor } from "common/src/utility/axioscalls";
import useToast from "../../hooks/useToast";

export const DoctorEditModal: React.FC<{
  doctor: DoctorProps;
  showUpdateModal: boolean;
  handleUpdateCancel: () => void;
  setUpdateLoading: (updateLoading: boolean) => void;
  setShowUpdateModal: (showUpdateModal: boolean) => void;
  refreshDoctors: () => void;
  value: string;
}> = ({
  doctor,
  showUpdateModal,
  handleUpdateCancel,
  setUpdateLoading,
  setShowUpdateModal,
  refreshDoctors,
  value,
}) => {
  const [doctorForm, setDoctorForm] = useState<DoctorProps>(doctor);
  const { setNotify, setNotifyType } = useToast();

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } }
  ) => {
    const { name, value } = e.target;

    setDoctorForm({ ...doctorForm, [name]: value });
  };

  const validateDoctorForm = (doctorForm: any) => {
    const { address, email, name, phone, practice, specialty } = doctorForm;
    if (address.trim() === "") return { valid: false, field: "Address" };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) return { valid: false, field: "Email" };

    if (name.trim() === "") return { valid: false, field: "Name" };
    if (practice.trim() === "") return { valid: false, field: "Practice" };
    if (specialty.trim() === "") return { valid: false, field: "Specialty" };

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) return { valid: false, field: "Phone" };

    return { valid: true };
  };

  const handleUpdateDoctor = () => {
    const updatedDoctorForm = {
      ...doctorForm,
    };

    const validation = validateDoctorForm(updatedDoctorForm);

    if (!validation.valid) {
      setNotifyType("danger");
      if (validation.field === "Email") {
        setNotify(`Please enter a valid email address.`);
      } else if (validation.field === "Phone") {
        setNotify(`Please enter a valid phone number.`);
      } else {
        setNotify(`Please fill in the required field : ${validation.field}.`);
      }
      return;
    }

    const fetchData = async () => {
      try {
        setShowUpdateModal(true);

        await updateDoctor({ id: value, ...updatedDoctorForm });

        refreshDoctors();
      } catch (err) {
        console.log(err);
      } finally {
        setShowUpdateModal(false);
      }
    };

    fetchData();
  };

  return (
    <IonModal
      isOpen={showUpdateModal}
      id="custom-modal"
      onDidDismiss={handleUpdateCancel}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#0D122A]">
        <p className="w-full text-center space-x-2 text-2xl font-bold my-3 bg-ctgr-100">
          <span>Edit</span>
          <span className="text-[#DC3E46]">Doctor</span>
        </p>

        <div>
          <p className=" text-base font-semibold mt-1">Full Name</p>

          <Input
            name="name"
            value={doctorForm.name}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Address</p>

          <Input
            name="address"
            value={doctorForm.address}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Email</p>

          <Input
            name="email"
            value={doctorForm.email}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Phone number</p>

          <Input
            name="phone"
            value={doctorForm.phone}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Specialty</p>

          <Input
            name="specialty"
            value={doctorForm.specialty}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Practice</p>

          <Input
            name="practice"
            value={doctorForm.practice}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleUpdateCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleUpdateDoctor}
          >
            Update
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default DoctorEditModal;

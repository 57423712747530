import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import Input from "../Input";
import { updateMilestone } from "common/src/utility/axioscalls";
import { MilestoneProps } from "common/src/types";

export const MilestoneEditModal: React.FC<{
  milestone: MilestoneProps;
  showUpdateModal: boolean;
  handleUpdateCancel: () => void;
  setUpdateLoading: (updateLoading: boolean) => void;
  setShowUpdateModal: (showUpdateModal: boolean) => void;
  refreshMilestones: () => void;
  value: string;
}> = ({
  milestone,
  showUpdateModal,
  handleUpdateCancel,
  setUpdateLoading,
  setShowUpdateModal,
  refreshMilestones,
  value,
}) => {
  const [milestoneForm, setMilestoneForm] = useState<MilestoneProps>(milestone);

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } }
  ) => {
    const { name, value } = e.target;

    setMilestoneForm({ ...milestoneForm, [name]: value });
  };

  const handleUpdateMilestone = () => {
    setShowUpdateModal(true);

    const fetchData = async () => {
      try {
        const updatedMilestoneForm = {
          ...milestoneForm,
        };

        await updateMilestone({ id: value, ...updatedMilestoneForm });

        refreshMilestones();
      } catch (err) {
        console.log(err);
      } finally {
        setShowUpdateModal(false);
      }
    };

    fetchData();
  };

  return (
    <IonModal
      isOpen={showUpdateModal}
      id="custom-modal"
      onDidDismiss={handleUpdateCancel}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#0D122A]">
        <p className="w-full text-center space-x-2 text-2xl font-bold my-3 bg-ctgr-100">
          <span>Edit</span>
          <span className="text-[#DC3E46]">Milestone</span>
        </p>

        <div>
          <p className="text-base font-semibold mt-1">Doctor</p>

          <Input
            name="doctor"
            value={milestoneForm.doctor}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Event</p>

          <Input
            name="event"
            value={milestoneForm.event}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Outcome</p>

          <Input
            name="outcome"
            value={milestoneForm.outcome}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Start date</p>

          <Input
            name="start_date"
            value={milestoneForm.start_date}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Notes (optional)</p>

          <textarea
            name="notes"
            value={milestoneForm.notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            }
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
            rows={3}
          />
        </div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleUpdateCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleUpdateMilestone}
          >
            Update
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default MilestoneEditModal;

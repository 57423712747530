"use client";
import { useState, ReactNode, useEffect, useMemo } from "react";
import AuthContext from "./AuthContext";
import {
  getGeneralInfo,
  getUserInfo,
  getNewActivity,
  setNewActivity,
} from "common/src/utility/axioscalls";

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [email, setEmail] = useState<string>("");
  const [isLogIn, setIsLogIn] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [uuid, setUuid] = useState<string>("");

  const setUserData = async (bodyData: any) => {
    const res = await setNewActivity(bodyData);

    setFirstName(res.data.first_name);
    setLastName(res.data.last_name);
    setEmail(res.data.email);
    setAvatar(res.data.profile_pic);
    setIsLogIn(true);
  };

  const cachedUserData = useMemo(() => {
    return {
      email,
      isLogIn,
      firstName,
      lastName,
      avatar,
      uuid,
    };
  }, [email, isLogIn, firstName, lastName, avatar, uuid]);

  useEffect(() => {
    const fetchData = async () => {
      const user_info = await getUserInfo();

      setUuid(user_info.data["cognito:username"]);

      const new_activity = await getNewActivity();

      if (new_activity.code === 404) {
        const res = await getUserInfo();

        if (res.code === 200) {
          const bodyData = {
            email: res.data.email,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            profile_pic: res.data.profile_pic,
          };

          await setUserData(bodyData);
        }
      } else if (new_activity.code === 200) {
        const data = await getGeneralInfo();

        if (data.code === 200) {
          setFirstName(data.data.general.first_name);
          setLastName(data.data.general.last_name);
          setEmail(data.data.general.email);
          setAvatar(data.data.general.profile_pic);
          setIsLogIn(true);
        }
      }
    };

    fetchData();
  }, [isLogIn]);

  return (
    <AuthContext.Provider
      value={{
        ...cachedUserData,
        setEmail,
        setIsLogIn,
        setFirstName,
        setLastName,
        setUuid,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import { useContext } from "react";
import RemoveModalContext from "../../../mobile/src/providers/RemoveModalContext";

/**
 * Custom hook for accessing the RemoveModal context.
 *
 * @returns {Object} The RemoveModal context object.
 * @throws {Error} If used outside of a RemoveModalProvider.
 */
export const useRemoveModal = () => {
  const context = useContext(RemoveModalContext);
  if (!context) {
    throw new Error("useRemoveModal must be used within a RemoveModalProvider");
  }
  return context;
};

export default useRemoveModal;

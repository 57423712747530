import React from "react";
import { MilestoneProps } from "common/src/types";
import MilestoneView from "./MilestoneView";

interface MilestoneViewListProps {
  milestones: MilestoneProps;
}

const MilestoneViewList: React.FC<MilestoneViewListProps> = ({
  milestones,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-3 bg-ctgr-100">
        <span>Health</span>
        <span className="text-[#DC3E46]">Milestones</span>
      </p>

      {milestones &&
        Object.entries(milestones).map(([id, milestone]) => (
          <MilestoneView key={id} milestone={milestone} />
        ))}
    </div>
  );
};

export default MilestoneViewList;

import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonCheckbox, IonSpinner } from "@ionic/react";
import { CheckboxChangeEventDetail } from "@ionic/core";
import { useHistory } from "react-router";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import {
  getMedicalCondition,
  updateMedicalCondition,
} from "common/src/utility/axioscalls";
import LoadingAnimation from "../../components/LoadingAnimation";
import useToast from "../../hooks/useToast";
import CustomDropdown from "../../components/CustomDropDown";
import { useAuth } from "../../hooks/useAuth";
import RedPanel from "../../components/RedPanel";

interface MedicalCondition {
  id: string;
  label: string;
}

const medicalConditions: MedicalCondition[] = [
  { id: "alzheimers", label: "I have alzheimer" },
  { id: "dementia", label: "I have dementia" },
  { id: "diabetic1", label: "I have type 1 diabetes" },
  { id: "diabetic2", label: "I have type 2 diabetes" },
  { id: "bloodThinners", label: "I'm on blood thinners" },
  { id: "epileptic", label: "I'm epileptic" },
  { id: "fallRisk", label: "I'm a fall risk" },
  { id: "organDonor", label: "I'm an organ donor" },
  { id: "pacemaker", label: "I have a pacemaker" },
  { id: "penicillinAllergy", label: "I have a penicillin allergy" },
];

const MedicalConditionItem: React.FC<{
  condition: MedicalCondition;
  checked: boolean;
  onChange: (id: string, checked: boolean) => void;
}> = ({ condition, checked, onChange }) => (
  <div className="flex justify-start items-center gap-3 border-[#DDDBD9] border p-3 rounded-lg bg-white hover:bg-[#EBEBEB]">
    <IonCheckbox
      labelPlacement="end"
      className="text-[#0D122A] text-base font-semibold border"
      color="success"
      checked={checked}
      onIonChange={(e: CustomEvent<CheckboxChangeEventDetail>) =>
        onChange(condition.id, e.detail.checked)
      }
    />
    <p className="text-[#0D122A] text-base font-semibold">{condition.label}</p>
  </div>
);

const MedicalConditions: React.FC = () => {
  const history = useHistory();
  const { setNotify, setNotifyType } = useToast();
  const [conditions, setConditions] = useState<{
    [key: string]: boolean;
  }>({
    alzheimers: false,
    dementia: false,
    diabetic1: false,
    diabetic2: false,
    bloodThinners: false,
    epileptic: false,
    fallRisk: false,
    organDonor: false,
    pacemaker: false,
    penicillinAllergy: false,
  });
  const [bloodType, setBloodType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const { firstName, lastName, avatar } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const res = await getMedicalCondition();
        if (res.code === 200) {
          setConditions({
            alzheimers: res.data.major_medical.alzheimers || false,
            dementia: res.data.major_medical.dementia || false,
            diabetic1: res.data.major_medical.diabetic_type1 || false,
            diabetic2: res.data.major_medical.diabetic_type2 || false,
            bloodThinners: res.data.major_medical.blood_thinners || false,
            epileptic: res.data.major_medical.epileptic || false,
            fallRisk: res.data.major_medical.fall_risk || false,
            organDonor: res.data.major_medical.organ_donor || false,
            pacemaker: res.data.major_medical.pacemaker || false,
            penicillinAllergy:
              res.data.major_medical.penicillin_allergy || false,
          });
        } else {
          setConditions({
            alzheimers: false,
            dementia: false,
            diabetic1: false,
            diabetic2: false,
            bloodThinners: false,
            epileptic: false,
            fallRisk: false,
            organDonor: false,
            pacemaker: false,
            penicillinAllergy: false,
          });
        }

        setBloodType(res.data.major_medical.blood_type || "");
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleConditionChange = (id: string, checked: boolean) => {
    setConditions((prev) => ({ ...prev, [id]: checked }));
  };

  const handleSaveMedicalCondition = async () => {
    try {
      const bodyData = {
        alzheimers: conditions.alzheimers,
        blood_thinners: conditions.bloodThinners,
        blood_type: bloodType,
        dementia: conditions.dementia,
        diabetic_type1: conditions.diabetic1,
        diabetic_type2: conditions.diabetic2,
        epileptic: conditions.epileptic,
        fall_risk: conditions.fallRisk,
        organ_donor: conditions.organDonor,
        pacemaker: conditions.pacemaker,
        penicillin_allergy: conditions.penicillinAllergy,
      };

      setSaveLoading(true);

      const res = await updateMedicalCondition(bodyData);

      if (res.code === 200) {
        setNotifyType("success");
        setNotify("Updated the information successfully!");
      }
    } catch (err) {
      setNotifyType("error");
      setNotify("Failed to update the information!");
      console.error("Error updating medical condition:", err);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <div className="text-[#0D122A]">
            <div className="px-5">
              <MainLogo />

              <BackIcon />

              <RedPanel />

              <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
                <span>Essential</span>
                <span className="text-[#DC3E46]"> Details</span>
              </p>

              <div className="mt-6 flex flex-col gap-3">
                <p className=" text-base font-semibold">Blood Type</p>

                <div className="border-[#DDDBD9] border rounded-lg">
                  <CustomDropdown
                    options={["Select Blood Type", "A", "B", "O", "AB"]}
                    value={bloodType}
                    onChange={(value: string) =>
                      setBloodType(value === "Select Blood Type" ? "" : value)
                    }
                    placeholder="Select a Blood Type"
                  />
                </div>

                <p className=" text-base font-semibold my-3">
                  Serious Medical Conditions
                </p>

                {medicalConditions.map((condition) => (
                  <MedicalConditionItem
                    key={condition.id}
                    condition={condition}
                    checked={conditions[condition.id]}
                    onChange={handleConditionChange}
                  />
                ))}
              </div>
            </div>

            <div className="px-5">
              <hr className="w-full h-[1px] bg-[#DDDBD9] mt-8 mb-3" />

              <button
                className="w-full rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-4 text-white text-lg my-4"
                onClick={handleSaveMedicalCondition}
                disabled={saveLoading}
              >
                {saveLoading ? (
                  <IonSpinner name="crescent" color="light" />
                ) : (
                  <span>Save</span>
                )}
              </button>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MedicalConditions;

import React, { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import Input from "../../components/Input";
import CustomDropdown from "../../components/CustomDropDown";
import { IonSpinner } from "@ionic/react";
import { addMedication } from "common/src/utility/axioscalls";
import useToast from "../../hooks/useToast";
import { useHistory } from "react-router";

interface MedicationProps {
  active: boolean;
  doctor: string;
  dosage: number | null;
  form: string;
  frequency: string;
  measurement: string;
  medication_name: string;
  notes: string;
}

const MedicationListAdd: React.FC = () => {
  const [medicationForm, setMedicationForm] = useState<MedicationProps>({
    active: true,
    doctor: "",
    dosage: null,
    form: "",
    frequency: "",
    measurement: "",
    medication_name: "",
    notes: "",
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const { setNotify, setNotifyType } = useToast();
  const history = useHistory();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "dosage") {
      setMedicationForm({
        ...medicationForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else {
      setMedicationForm({ ...medicationForm, [e.target.name]: e.target.value });
    }
  };

  const validateMedicationForm = () => {
    const { medication_name, dosage, frequency, form, doctor } = medicationForm;
    if (medication_name.trim() === "")
      return { valid: false, field: "Medication Name" };
    if (dosage === null) return { valid: false, field: "Dosage" };
    if (frequency.trim() === "") return { valid: false, field: "Frequency" };
    if (form.trim() === "") return { valid: false, field: "Form" };
    if (doctor.trim() === "") return { valid: false, field: "Doctor" };
    return { valid: true };
  };

  const handleAddMedication = async () => {
    const validation = validateMedicationForm();
    if (!validation.valid) {
      setNotifyType("danger");
      setNotify(`Please fill in the required field : ${validation.field}.`);
      return;
    }

    setSaveLoading(true);

    try {
      await addMedication(medicationForm);

      history.push("/main/medical-data/medication-list");
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className="px-5">
          <MainLogo />

          <BackIcon />

          <RedPanel />

          <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
            <span>Add</span>
            <span className="text-[#DC3E46]">Medication</span>
          </p>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Medication
            </p>

            <Input
              name="medication_name"
              value={medicationForm.medication_name}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Dosage
            </p>

            <Input
              name="dosage"
              value={medicationForm.dosage}
              onChange={onChange}
              placeholder=""
              type="number"
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Measurement
            </p>

            <CustomDropdown
              options={[
                "Milligrams (mg)",
                "Grams (g)",
                "Micrograms (mcg)",
                "Milliliters (mL)",
                "Liters (L)",
                "Units (U)",
                "Percent (%)",
                "Milliequivalents (mEq)",
                "Drops (gtt)",
              ]}
              value={medicationForm.measurement || ""}
              onChange={(value) =>
                onChange({
                  target: { name: "measurement", value },
                } as React.ChangeEvent<HTMLInputElement>)
              }
              placeholder="Select a Measurement"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Frequency
            </p>

            <CustomDropdown
              options={[
                "Once daily",
                "Twice daily",
                "Three times daily",
                "Every 4 hours",
                "Every 6 hours",
                "As needed",
                "Once a week",
                "Every month",
              ]}
              value={medicationForm.frequency || ""}
              onChange={(value) =>
                onChange({
                  target: { name: "frequency", value },
                } as React.ChangeEvent<HTMLInputElement>)
              }
              placeholder="Select a Frequency"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">Form</p>

            <CustomDropdown
              options={[
                "Tablet",
                "Capsule",
                "Liquid",
                "Injectable",
                "Topical",
                "Patch",
                "Spray",
                "Inhaler",
                "Lozenge",
                "Suppository",
                "Granule",
                "Film",
              ]}
              value={medicationForm.form || ""}
              onChange={(value) =>
                onChange({
                  target: { name: "form", value },
                } as React.ChangeEvent<HTMLInputElement>)
              }
              placeholder="Select a Form"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Doctor
            </p>

            <Input
              name="doctor"
              value={medicationForm.doctor}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Notes (optional)
            </p>

            <textarea
              name="notes"
              value={medicationForm.notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
              }
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
              rows={3}
            />
          </div>
        </div>

        <div className="px-5">
          <div
            className="w-full px-5 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-4 text-white text-lg my-4"
            onClick={handleAddMedication}
          >
            {saveLoading ? (
              <IonSpinner
                name="crescent"
                color="light"
                style={{ fontSize: "30px" }}
              />
            ) : (
              <span>Save</span>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MedicationListAdd;

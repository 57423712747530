import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import ToastProvider from "./providers/ToastProvider";
import AuthProvider from "./providers/AuthProvider";
import RemoveModalProvider from "./providers/RemoveModalProvider";
import SaveModalProvider from "./providers/SaveModalProvider";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ToastProvider>
      <AuthProvider>
        <SaveModalProvider>
          <RemoveModalProvider>
            <App />
          </RemoveModalProvider>
        </SaveModalProvider>
      </AuthProvider>
    </ToastProvider>
  </React.StrictMode>
);

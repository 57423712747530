import React from "react";
import { DoctorProps } from "common/src/types";
import DoctorView from "./DoctorView";

interface DoctorViewListProps {
  doctors: DoctorProps;
}

const DoctorViewList: React.FC<DoctorViewListProps> = ({ doctors }) => {
  return (
    <div className="flex flex-col gap-4">
      {doctors &&
        Object.entries(doctors).map(([id, doctor]) => (
          <DoctorView key={id} doctor={doctor} />
        ))}
    </div>
  );
};

export default DoctorViewList;

import { createContext } from "react";

type AuthContextProps = {
  email: string;
  setEmail: (email: string) => void;
  isLogIn: boolean;
  setIsLogIn: (login: boolean) => void;
  firstName: string;
  setFirstName: (fullName: string) => void;
  lastName: string;
  setLastName: (fullName: string) => void;
  avatar: string;
  uuid: string;
  setUuid: (uuid: string) => void;
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export default AuthContext;

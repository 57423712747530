import { useState } from "react";
import TrashIcon from "common/public/icons/icon-trash.png";
import EditIcon from "common/public/icons/edit.png";
import { deleteMilestone } from "common/src/utility/axioscalls";
import MilestoneViewModal from "./MilestoneViewModal";
import ItemRemoveModal from "../ItemRemoveModal";
import MilestoneEditModal from "./MilestoneEditModal";
import { MilestoneProps } from "common/src/types";

const MileStoneCard: React.FC<{
  value: string;
  milestone: MilestoneProps;
  setUpdateLoading: (deleteLoading: boolean) => void;
  showRemoveModal: boolean;
  setShowRemoveModal: (showRemoveModal: boolean) => void;
  refreshMilestones: () => void;
}> = ({
  value,
  milestone,
  setUpdateLoading,
  showRemoveModal,
  setShowRemoveModal,
  refreshMilestones,
}) => {
  const [showMilestoneModal, setShowMilestoneModal] = useState<boolean>(false);

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const handleRemoveCancel = () => {
    setShowRemoveModal(false);
  };

  const handleRemoveMilestone = () => {
    setShowRemoveModal(true);
  };

  const handleDeleteMilestone = () => {
    setUpdateLoading(true);
    setShowRemoveModal(false);

    const fetchData = async () => {
      const data = {
        uuid: value,
      };

      try {
        await deleteMilestone(data);
        refreshMilestones(); // Call the refresh function after deletion
      } catch (err) {
        console.log(err);
      } finally {
        setUpdateLoading(false);
      }
    };

    fetchData();
  };

  const handleEditMilestone = () => {
    setShowUpdateModal(true);
  };

  const handleUpdateCancel = () => {
    setShowUpdateModal(false);
  };

  const handleOpenMilestoneModal = () => {
    setShowMilestoneModal(true);
  };

  const handleCloseMilestoneModal = () => {
    setShowMilestoneModal(false);
  };

  return (
    <div className="border border-[#DDDBD9] bg-white p-3 rounded-lg text-[#0D122A] text-base">
      <div onClick={handleOpenMilestoneModal}>
        <p className="font-semibold">
          {milestone.event} - {milestone.start_date}
        </p>

        <p>{milestone.doctor}</p>

        <p>{milestone.notes}</p>

        <p>{milestone.outcome}</p>
      </div>

      <div className="flex gap-3 justify-end -mt-4">
        <img src={EditIcon} onClick={handleEditMilestone} />
        <img src={TrashIcon} onClick={handleRemoveMilestone} />
      </div>

      <MilestoneViewModal
        milestone={milestone}
        showMilestoneModal={showMilestoneModal}
        handleCloseMilestoneModal={handleCloseMilestoneModal}
      />

      <ItemRemoveModal
        showRemoveModal={showRemoveModal}
        handleRemoveCancel={handleRemoveCancel}
        handleDeleteItem={handleDeleteMilestone}
      />

      <MilestoneEditModal
        milestone={milestone}
        showUpdateModal={showUpdateModal}
        handleUpdateCancel={handleUpdateCancel}
        setUpdateLoading={setUpdateLoading}
        setShowUpdateModal={setShowUpdateModal}
        refreshMilestones={refreshMilestones}
        value={value}
      />
    </div>
  );
};

export default MileStoneCard;

import { useState, ReactNode } from "react";
import RemoveModalContext from "./RemoveModalContext";

type RemoveModalProviderProps = {
  children: ReactNode;
};

const RemoveModalProvider = ({ children }: RemoveModalProviderProps) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [actionType, setActionType] = useState("");

  return (
    <RemoveModalContext.Provider
      value={{
        showRemoveModal,
        setShowRemoveModal,
        actionType,
        setActionType,
      }}
    >
      {children}
    </RemoveModalContext.Provider>
  );
};

export default RemoveModalProvider;

import { createContext } from "react";

type ToastContextProps = {
  toastFlag: boolean;
  toggleModal: () => void;
  setNotify: (message: string) => void;
  text: string;
  setNotifyType: (content: string) => void;
  notifyType: string;
};

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export default ToastContext;

import React, { useState } from "react";
import { IonContent, IonPage, IonCheckbox, IonItem } from "@ionic/react";
import MainHeader from "../../components/MainHeader";
import { useHistory } from "react-router";
import useRemoveModal from "../../hooks/useRemoveModal";
import rightIcon from "common/public/icons/icon-right.png";

const SurgicalAndMilestoneHistory: React.FC = () => {
  const history = useHistory();
  const { showRemoveModal, setShowRemoveModal } = useRemoveModal();

  const handleAddEvent = () => {
    history.push("/main/medical-data/surgical-and-milestone-history-add");
  };

  const handleRemoveSurgicalAndMilestoneHistory = () => {
    setShowRemoveModal(true);
  };

  return (
    <IonPage>
      <IonContent>
        <MainHeader />

        <div className="px-5">
          <p className="flex justify-start flex-wrap items-center gap-2 text-[#87827B] text-lg font-bold">
            <span onClick={() => history.goBack()} className="cursor-pointer">
              Dashboard
            </span>

            <img src={rightIcon} className="w-4 h-4" />

            <span onClick={() => history.goBack()}>My Medical History</span>
          </p>

          <div className="text-[#0D122A] text-start text-[32px] font-bold my-6">
            Surgical and Milestone History
          </div>

          <div className="flex justify-start items-center gap-3 border-[#DDDBD9] border p-3 rounded-lg bg-[#EBEBEB]">
            <IonCheckbox
              labelPlacement="end"
              className="text-[#0D122A] text-base font-semibold border-none"
              color="success"
            />

            <p className="text-[#0D122A] text-base font-semibold">
              Show this on my VitaTrack page
            </p>
          </div>

          <div className="border border-[#DDDBD9] bg-white p-3 rounded-lg mt-6 text-[#0D122A]">
            <p className="text-base font-semibold">12 Apr 2024</p>

            <p className="text-lg font-bold">Appendectomy</p>

            <p className="text-base font-semibold mt-1">Dr. John Smith</p>

            <p className="text-sm font-semibold">Successful - minor issues</p>

            <div className="flex justify-end -mt-4">
              <img
                src="/icons/icon-trash.png"
                onClick={handleRemoveSurgicalAndMilestoneHistory}
              />
            </div>
          </div>

          <button
            className="bg-white text-[#87827B] text-lg font-semibold mt-1"
            onClick={handleAddEvent}
          >
            + Add Event
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurgicalAndMilestoneHistory;

import { Capacitor } from "@capacitor/core";

// ... existing code ...

export const getAxiosInstance = async () => {
  let instance;
  if (Capacitor.isNativePlatform()) {
    instance = (
      await import("../../../../mobile/src/utility/axioscalls/axiosInstance")
    ).default;
  } else {
    // handle web case or throw an error
    instance = (
      await import("../../../../mobile/src/utility/axioscalls/axiosInstance")
    ).default;
  }

  if (!instance) {
    throw new Error("Failed to create an Axios instance");
  }

  return instance;
};

export * from "./allInformation";
export * from "./generalInformation";
export * from "./medicalCondition";
export * from "./medications";
export * from "./doctors";
export * from "./healthMilestones";
export * from "./illnesses";

import { DoctorProps } from "common/src/types";

const DoctorView: React.FC<{ doctor: DoctorProps }> = ({ doctor }) => {
  const { name, specialty, address, phone, email } = doctor;

  return (
    <div className="w-full mt-6 text-[#0D122A] flex flex-col gap-6">
      <p className="text-base">
        {name} - {specialty}
      </p>
      <p className="text-base mt-1">{address}</p>
      <p className="text-base">{phone}</p>
      <p className="text-base">{email}</p>
    </div>
  );
};

export default DoctorView;

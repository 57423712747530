import React, { useState } from "react";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import Input from "../../components/Input";
import useToast from "../../hooks/useToast";
import { useHistory } from "react-router";
import { addMilestone } from "common/src/utility/axioscalls";
import { MilestoneProps } from "common/src/types";

const HealthMilestoneAdd: React.FC = () => {
  const { setNotify, setNotifyType } = useToast();
  const history = useHistory();

  const [saveLoading, setSaveLoading] = useState(false);
  const [milestoneForm, setMilestoneForm] = useState<MilestoneProps>({
    doctor: "",
    event: "",
    notes: "",
    outcome: "",
    start_date: "",
  });

  const validateMilestoneForm = () => {
    const { doctor, event, notes, outcome, start_date } = milestoneForm;
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    if (doctor.trim() === "") return { valid: false, field: "Doctor" };
    if (event.trim() === "") return { valid: false, field: "Event" };
    if (outcome.trim() === "") return { valid: false, field: "Outcome" };
    if (start_date.trim() === "") return { valid: false, field: "Start Date" };
    if (!datePattern.test(start_date.trim()))
      return { valid: false, field: "Start Date Format" };

    return { valid: true };
  };

  const handleAddMilestone = async () => {
    const validation = validateMilestoneForm();

    if (!validation.valid) {
      setNotifyType("danger");
      if (validation.field === "Start Date Format") {
        setNotify("Please enter the start date in the format MM/DD/YYYY.");
      } else {
        setNotify(`Please fill in the required field: ${validation.field}.`);
      }
      return;
    }

    setSaveLoading(true);

    try {
      await addMilestone(milestoneForm);

      history.push("/main/medical-data/health-milestones");
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMilestoneForm({ ...milestoneForm, [e.target.name]: e.target.value });
  };

  return (
    <IonPage>
      <IonContent>
        <div className="px-5">
          <MainLogo />

          <BackIcon />

          <RedPanel />

          <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
            <span>Add</span>
            <span className="text-[#DC3E46]">Milestone</span>
          </p>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Doctor
            </p>

            <Input
              name="doctor"
              value={milestoneForm.doctor}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">Event</p>

            <Input
              name="event"
              value={milestoneForm.event}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Outcome
            </p>

            <Input
              name="outcome"
              value={milestoneForm.outcome}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Start date
            </p>

            <Input
              name="start_date"
              value={milestoneForm.start_date}
              onChange={onChange}
              placeholder="01/01/2023"
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Notes (optional)
            </p>

            <textarea
              name="notes"
              value={milestoneForm.notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
              }
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
              rows={3}
            />
          </div>
        </div>

        <div className="px-5">
          <div
            className="w-full px-5 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-4 text-white text-lg my-4"
            onClick={handleAddMilestone}
          >
            {saveLoading ? (
              <IonSpinner
                name="crescent"
                color="light"
                style={{ fontSize: "30px" }}
              />
            ) : (
              <span>Save</span>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HealthMilestoneAdd;

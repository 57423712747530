import React from "react";
import { MilestoneProps } from "common/src/types";

export const MilestoneViewModal: React.FC<{
  milestone: MilestoneProps;
}> = ({ milestone }) => {
  const { doctor, event, outcome, start_date, notes } = milestone || {};

  const fields = [
    { label: "Doctor", value: doctor },
    { label: "Event", value: event },
    { label: "Outcome", value: outcome },
    { label: "Start Date", value: start_date },
    { label: "Notes", value: notes },
  ];

  return (
    <div className="h-full flex flex-col justify-start gap-6">
      {fields.map(
        ({ label, value }) =>
          value && (
            <div key={label} className="flex items-center gap-2">
              <p>{label}:</p>
              <p>{value}</p>
            </div>
          )
      )}
    </div>
  );
};

export default MilestoneViewModal;

import React from "react";
import { InfoText } from "../const";
interface ImportantConditionsProps {
  condition: any;
}

const ImportantConditionsView: React.FC<ImportantConditionsProps> = ({
  condition,
}) => {
  const hasAnyCondition = Object.values(condition).some(Boolean);

  return (
    <div>
      {hasAnyCondition && (
        <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
          <span>Important</span>
          <span className="text-[#DC3E46]">Conditions</span>
        </p>
      )}

      {condition?.alzheimers && <InfoText>I have alzheimer</InfoText>}
      {condition?.dementia && <InfoText>I have dementia</InfoText>}
      {condition?.diabetic_type1 && <InfoText>I have type 1 diabetes</InfoText>}
      {condition?.diabetic_type2 && <InfoText>I have type 2 diabetes</InfoText>}
      {condition?.blood_thinners && <InfoText>I'm on blood thinners</InfoText>}
      {condition?.epileptic && <InfoText>I'm epileptic</InfoText>}
      {condition?.fall_risk && <InfoText>I'm a fall risk</InfoText>}
      {condition?.organ_donor && <InfoText>I'm an organ donor</InfoText>}
      {condition?.pacemaker && <InfoText>I have a pacemaker</InfoText>}
      {condition?.penicillin_allergy && (
        <InfoText>I have a penicillin allergy</InfoText>
      )}
      {condition?.blood_type && (
        <InfoText>My blood type is {condition.blood_type}</InfoText>
      )}
    </div>
  );
};

export default ImportantConditionsView;

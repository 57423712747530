import { useContext } from "react";
import SaveModalContext from "../../../mobile/src/providers/SaveModalContext";

/**
 * Custom hook for accessing the SaveModal context.
 *
 * @returns {Object} The SaveModal context object.
 * @throws {Error} If used outside of a SaveModalProvider.
 */
export const useSaveModal = () => {
  const context = useContext(SaveModalContext);
  if (!context) {
    throw new Error("useSaveModal must be used within a SaveModalProvider");
  }
  return context;
};

export default useSaveModal;

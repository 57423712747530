import React from "react";
import { IonModal } from "@ionic/react";

export const ItemRemoveModal: React.FC<{
  showRemoveModal: boolean;
  handleRemoveCancel: () => void;
  handleDeleteItem: () => void;
}> = ({ showRemoveModal, handleRemoveCancel, handleDeleteItem }) => {
  return (
    <IonModal
      isOpen={showRemoveModal}
      id="custom-modal"
      onDidDismiss={handleRemoveCancel}
    >
      <div className="w-full p-6 bg-white">
        <div className="text-[#0D122A] text-[32px]">Remove item?</div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleRemoveCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleDeleteItem}
          >
            Remove
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default ItemRemoveModal;

import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import useAmplify from "../../hooks/useAmplify";
import useToast from "../../hooks/useToast";
import {
  ValidatePassword,
  ValidateEmail,
} from "common/src/utility/validate/inputValidation";
import logo from "common/public/icons/logo-header.png";
import messageIcon from "common/public/icons/message_light.png";
import passwordIcon from "common/public/icons/password_light.png";
import LoadingAnimation from "../../components/LoadingAnimation";
import { useAuth } from "../../hooks/useAuth";

const SignIn: React.FC = () => {
  const history = useHistory();
  const auth = useAmplify();
  const { setIsLogIn, isLogIn } = useAuth();
  const { setNotify, setNotifyType } = useToast();

  const [formValue, setFormValue] = useState({
    fullname: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLogIn) {
      history.push("/main/my-link");
    }

    setLoading(false);
  }, [isLogIn]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSignUp = () => {
    history.push("/auth/sign-up");
  };

  const handleForgotPassword = () => {
    history.push("/auth/forgot-password");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    if (!ValidateEmail(formValue.email)) {
      setNotifyType("danger");
      setNotify("Invalid email!");
      setLoading(false);
      return;
    }

    if (!ValidatePassword(formValue.password)) {
      setNotifyType("danger");
      setNotify("Invalid password!");
      setLoading(false);
      return;
    }

    try {
      const user = await auth.signIn({
        username: formValue.email,
        password: formValue.password,
      });

      if (user.isSignedIn) {
        setIsLogIn(true);

        history.push("/main/my-link");
      } else {
        setNotifyType("danger");
        setNotify("Username or password is incorrect");
      }
    } catch (err) {
      if (err?.toString().includes("UserAlreadyAuthenticatedException")) {
        history.push("/main/my-link");
      } else {
        setNotifyType("danger");
        setNotify("Username or password is incorrect");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <form onSubmit={handleSubmit}>
          <div
            className="flex flex-col w-full justify-between pb-16"
            style={{ height: `calc(100vh - 46px)` }}
          >
            <div className="w-full px-5 grow">
              <img className="mx-auto my-6 w-[292px] h-[74px]" src={logo} />

              <p className="font-semibold text-lg text-[#313131] text-center leading-6">
                Your medical history, whenever it's needed.
              </p>

              <p className="w-full text-center text-[44px] font-bold text-[#292941] mt-3 bg-ctgr-100">
                Log <span className="text-[#DC3E46]">In</span>
              </p>

              <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                <img src={messageIcon} />

                <input
                  className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                  placeholder="email@example.com"
                  onChange={onChange}
                  value={formValue.email}
                  name="email"
                />
              </div>

              <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                <img src={passwordIcon} />

                <input
                  className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                  placeholder="******"
                  type="password"
                  onChange={onChange}
                  value={formValue.password}
                  name="password"
                />
              </div>

              <div className="text-right">
                <p
                  className="cursor-pointer text-[#E81D04] my-6 font-semibold"
                  onClick={handleForgotPassword}
                >
                  Forgot password?
                </p>
              </div>
            </div>

            <div className="w-full px-5">
              <button
                className="w-full mt-3 text-white font-semibold text-lg py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2"
                type="submit"
              >
                <span>Sign In</span>
              </button>

              <div className="text-[#0D122A] font-semibold text-center mt-4 space-x-2">
                <span>Don't have an account?</span>

                <span
                  onClick={handleSignUp}
                  className="text-[#E81D04] font-semibold cursor-pointer mt-3"
                >
                  Sign Up,
                </span>

                <span>it's free!</span>
              </div>
            </div>
          </div>
        </form>

        {loading === true && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default SignIn;

import { IonModal } from "@ionic/react";
import "./RemoveModal.css";
import useRemoveModal from "../hooks/useRemoveModal";

const RemoveModal: React.FC = () => {
  const { showRemoveModal, setShowRemoveModal } = useRemoveModal();

  const handleCancel = () => {
    setShowRemoveModal(false);
  };

  const handleRemove = () => {
    setShowRemoveModal(false);
  };

  return (
    <IonModal
      isOpen={showRemoveModal}
      id="custom-modal"
      onDidDismiss={handleCancel}
    >
      <div className="w-full p-6 bg-white">
        <div className="text-[#0D122A] text-[32px]">Remove item?</div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleRemove}
          >
            Remove
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default RemoveModal;

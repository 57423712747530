import { getAxiosInstance } from ".";
import { GeneralInformationFormValues } from "../../types";

export const getUserInfo = async () => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.get(`/v1/userinfo`);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const getGeneralInfo = async () => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.get(`/v1/activity/general`);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const updateGeneralInfo = async (
  formData: GeneralInformationFormValues
) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.post(`/v1/activity/general`, formData);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const getWeBid = async () => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.get(`/v1/webid/default`);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

import { getAxiosInstance } from ".";

export const getHealthMilestones = async () => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.get(`/v1/activity/milestones`);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const updateMilestone = async (data: any) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.post(`/v1/activity/milestones`, data);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const addMilestone = async (data: any) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.put(`/v1/activity/milestones`, data);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const deleteMilestone = async (data: any) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.delete(`/v1/activity/milestones`, { data });
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

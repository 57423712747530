import React, { useState, useEffect } from "react";
import { InfoText } from "../const";
import { Country, State } from "country-state-city";

interface PersonalInformationProps {
  generalInformation: any;
}

const PersonalInformationView: React.FC<PersonalInformationProps> = ({
  generalInformation,
}) => {
  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const filteredCountries = allCountries.filter((country) =>
      ["US", "CA", "MX"].includes(country.isoCode)
    );
    setCountries(filteredCountries);

    const country_states = State.getStatesOfCountry(
      generalInformation?.country || "US"
    );

    setStates(country_states);
  }, []);

  return (
    <div>
      <p className="w-full text-center space-x-2 text-2xl font-bold text-[#DC3E46] my-3 bg-ctgr-100">
        Personal
      </p>

      <InfoText>{generalInformation?.first_name}</InfoText>

      <InfoText>{generalInformation?.last_name}</InfoText>

      <InfoText>{generalInformation?.phone}</InfoText>

      <InfoText>{generalInformation?.address1}</InfoText>

      <InfoText>{generalInformation?.address2}</InfoText>

      <InfoText>{generalInformation?.height}</InfoText>

      <InfoText>{generalInformation?.city}</InfoText>

      <InfoText>{generalInformation?.zipcode}</InfoText>

      <div className="flex items-center mt-6 gap-2">
        {generalInformation?.country && (
          <img
            src={`https://flagcdn.com/w20/${generalInformation?.country.toLowerCase()}.png`}
            alt={`flag`}
            className="w-5 h-3 object-cover"
          />
        )}

        <div className="text-base font-medium">
          {generalInformation?.country
            ? countries.find((c) => c.isoCode === generalInformation?.country)
                ?.name || ""
            : ""}
        </div>
      </div>

      <InfoText>
        {generalInformation?.state
          ? states.find((s) => s.isoCode === generalInformation?.state)?.name ||
            ""
          : ""}
      </InfoText>

      <InfoText>{generalInformation?.gender}</InfoText>

      <InfoText>{generalInformation?.eye_color}</InfoText>

      <InfoText>{generalInformation?.hair_color}</InfoText>

      <InfoText>{generalInformation?.height}</InfoText>

      <InfoText>{generalInformation?.weight}</InfoText>

      <InfoText>{generalInformation?.birth_date}</InfoText>
    </div>
  );
};

export default PersonalInformationView;

import { IonToast } from "@ionic/react";
import useToast from "../hooks/useToast";

const ToastNotify: React.FC = () => {
  const { toastFlag, toggleModal, text, notifyType } = useToast();

  return (
    <IonToast
      isOpen={toastFlag}
      onDidDismiss={() => toggleModal()}
      message={text}
      duration={2000}
      color={notifyType}
      cssClass="text-center font-bold bold-text mt-12 p-6 text-[16px]"
      position="top"
    />
  );
};

export default ToastNotify;

import React from "react";
import { ChronicIllnessProps } from "common/src/types";
import { IonModal } from "@ionic/react";

export const ChronicIllnessViewModal: React.FC<{
  illness: ChronicIllnessProps;
  showIllnessModal: boolean;
  handleCloseIllnessModal: () => void;
}> = ({ illness, showIllnessModal, handleCloseIllnessModal }) => {
  return (
    <IonModal
      isOpen={showIllnessModal}
      id="custom-modal"
      onDidDismiss={handleCloseIllnessModal}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#292941]">
        <p className="w-full text-center space-x-2 text-2xl font-bold my-3 bg-ctgr-100">
          <span>Illness</span>
          <span className="text-[#DC3E46]">Details</span>
        </p>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Name:</p>
          <p>{illness?.name}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Diagnosis Date:</p>
          <p>{illness?.diagnosis_date}</p>
        </div>

        {illness?.notes && (
          <div className="flex items-center gap-2">
            <p className="font-semibold">Notes:</p>
            <p>{illness?.notes}</p>
          </div>
        )}

        <div className="flex justify-end mt-6">
          <button
            onClick={handleCloseIllnessModal}
            className="w-fit px-3 py-2 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg z-10"
          >
            Close
          </button>
        </div>
      </div>
    </IonModal>
  );
};

export default ChronicIllnessViewModal;

import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import Input from "../Input";
import { ChronicIllnessProps } from "common/src/types";
import { updateDoctor, updateIllnesses } from "common/src/utility/axioscalls";
import useToast from "../../hooks/useToast";

export const ChronicIllnessEditModal: React.FC<{
  illness: ChronicIllnessProps;
  showUpdateModal: boolean;
  handleUpdateCancel: () => void;
  setUpdateLoading: (updateLoading: boolean) => void;
  setShowUpdateModal: (showUpdateModal: boolean) => void;
  refreshIllnesses: () => void;
  value: string;
}> = ({
  illness,
  showUpdateModal,
  handleUpdateCancel,
  setUpdateLoading,
  setShowUpdateModal,
  refreshIllnesses,
  value,
}) => {
  const [illnessForm, setIllnessForm] = useState<ChronicIllnessProps>(illness);
  const { setNotify, setNotifyType } = useToast();

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } }
  ) => {
    const { name, value } = e.target;

    setIllnessForm({ ...illness, [name]: value });
  };

  const validateIllnessesForm = () => {
    const { diagnosis_date, name, notes } = illnessForm;
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    if (name.trim() === "") return { valid: false, field: "Name" };
    if (diagnosis_date.trim() === "")
      return { valid: false, field: "Diagnosis Date" };
    if (!datePattern.test(diagnosis_date.trim()))
      return { valid: false, field: "Diagnosis Date Format" };

    return { valid: true };
  };

  const handleUpdateIllness = () => {
    const updatedIllnessForm = {
      ...illnessForm,
    };

    const validation = validateIllnessesForm();

    if (!validation.valid) {
      setNotifyType("danger");
      if (validation.field === "Email") {
        setNotify(`Please enter a valid email address.`);
      } else if (validation.field === "Phone") {
        setNotify(`Please enter a valid phone number.`);
      } else {
        setNotify(`Please fill in the required field : ${validation.field}.`);
      }
      return;
    }

    const fetchData = async () => {
      try {
        setShowUpdateModal(true);

        await updateIllnesses({ id: value, ...updatedIllnessForm });

        refreshIllnesses();
      } catch (err) {
        console.log(err);
      } finally {
        setShowUpdateModal(false);
      }
    };

    fetchData();
  };

  return (
    <IonModal
      isOpen={showUpdateModal}
      id="custom-modal"
      onDidDismiss={handleUpdateCancel}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#292941]">
        <p className="w-full text-center space-x-2 text-2xl font-bold  my-3 bg-ctgr-100">
          <span>Edit</span>
          <span className="text-[#DC3E46]">Illness</span>
        </p>

        <div>
          <p className="text-[#0D122A] text-base font-semibold mt-1">
            Full Name
          </p>

          <Input
            name="name"
            value={illnessForm.name}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg text-[#0D122A]"
          />
        </div>

        <div>
          <p className="text-[#0D122A] text-base font-semibold mt-1">
            Diagnosis Date
          </p>

          <Input
            name="diagnosis_date"
            value={illnessForm.diagnosis_date}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg text-[#0D122A]"
          />
        </div>

        <div className="flex flex-col gap-3">
          <p className="text-[#0D122A] text-base font-semibold mt-1">
            Notes (optional)
          </p>

          <textarea
            name="notes"
            value={illnessForm.notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            }
            className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            rows={3}
          />
        </div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleUpdateCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleUpdateIllness}
          >
            Update
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default ChronicIllnessEditModal;

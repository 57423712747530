import { useState } from "react";
import { IonCheckbox } from "@ionic/react";
import TrashIcon from "common/public/icons/icon-trash.png";
import EditIcon from "common/public/icons/edit.png";
import { deleteMedication } from "common/src/utility/axioscalls";
import MedicationViewModal from "./MedicationViewModal";
import ItemRemoveModal from "../ItemRemoveModal";
import MedicationEditModal from "./MedicationEditModal";

export interface MedicationProps {
  active: boolean;
  doctor: string;
  dosage: number;
  form: string;
  frequency: string;
  measurement: string;
  medication_name: string;
  notes: string;
}

const MedicationCard: React.FC<{
  value: string;
  medication: MedicationProps;
  setUpdateLoading: (deleteLoading: boolean) => void;
  showRemoveModal: boolean;
  setShowRemoveModal: (showRemoveModal: boolean) => void;
  refreshMedications: () => void; // New prop for refreshing medications
}> = ({
  value,
  medication,
  setUpdateLoading,
  showRemoveModal,
  setShowRemoveModal,
  refreshMedications, // Destructure the new prop
}) => {
  const [showMedicationModal, setShowMedicationModal] =
    useState<boolean>(false);

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const handleRemoveCancel = () => {
    setShowRemoveModal(false);
  };

  const handleRemoveMedication = () => {
    setShowRemoveModal(true);
  };

  const handleDeleteMedication = () => {
    setUpdateLoading(true);
    setShowRemoveModal(false);

    const fetchData = async () => {
      const data = {
        uuid: value,
      };

      try {
        await deleteMedication(data);
        refreshMedications(); // Call the refresh function after deletion
      } catch (err) {
        console.log(err);
      } finally {
        setUpdateLoading(false);
      }
    };

    fetchData();
  };

  const handleEditMedication = () => {
    setShowUpdateModal(true);
  };

  const handleUpdateCancel = () => {
    setShowUpdateModal(false);
  };

  const handleOpenMedicationModal = () => {
    setShowMedicationModal(true);
  };

  const handleCloseMedicationModal = () => {
    setShowMedicationModal(false);
  };

  return (
    <div className="border border-[#DDDBD9] bg-white p-3 rounded-lg text-[#0D122A] text-base">
      <div onClick={handleOpenMedicationModal}>
        <p className="font-semibold">
          {medication.medication_name} - {medication.dosage}{" "}
          {medication.measurement} / {medication.frequency}
        </p>

        <p>{medication.doctor}</p>

        <p>{medication.notes}</p>

        {medication.active === false && <p>NOT taking currently</p>}
      </div>

      <div className="flex gap-3 justify-end -mt-4">
        <img src={EditIcon} onClick={handleEditMedication} />
        <img src={TrashIcon} onClick={handleRemoveMedication} />
      </div>

      <MedicationViewModal
        medication={medication}
        showMedicationModal={showMedicationModal}
        handleCloseMedicationModal={handleCloseMedicationModal}
      />

      <ItemRemoveModal
        showRemoveModal={showRemoveModal}
        handleRemoveCancel={handleRemoveCancel}
        handleDeleteItem={handleDeleteMedication}
      />

      <MedicationEditModal
        medication={medication}
        showUpdateModal={showUpdateModal}
        handleUpdateCancel={handleUpdateCancel}
        setUpdateLoading={setUpdateLoading}
        setShowUpdateModal={setShowUpdateModal}
        refreshMedications={refreshMedications}
        value={value}
      />
    </div>
  );
};

export default MedicationCard;

import { Amplify } from "aws-amplify";
import * as Auth from "aws-amplify/auth";
import awsmobile from "../../../mobile/src/config/awsExport";

/**
 * Custom hook for configuring and using AWS Amplify authentication.
 *
 * @returns {Object} An object containing all the exported members from the AWS Amplify Auth module.
 */
const useAmplify = (): typeof Auth => {
  Amplify.configure(awsmobile);
  return { ...Auth };
};

export default useAmplify;

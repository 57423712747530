import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import { useAuth } from "../../hooks/useAuth";
import ChronicIllnessCard from "../../components/chronic-illness/ChronicIllnessCard";
import { getIllnesses } from "common/src/utility/axioscalls";
import LoadingAnimation from "../../components/LoadingAnimation";

const ChronicIllnesses: React.FC = () => {
  const history = useHistory();
  const { firstName, lastName, avatar } = useAuth();

  const [loading, setLoading] = useState(false);
  const [illnesses, setIllnesses] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const fetchIllnesses = async () => {
    try {
      setLoading(true);

      const res = await getIllnesses();
      setIllnesses(res.data.illnesses);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIllnesses();
  }, []);

  const handleAddIllness = () => {
    history.push("/main/medical-data/chronic-illnesses-add");
  };

  const handleRemoveChronic = () => {
    setShowRemoveModal(true);
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className="px-5 pb-5 flex flex-col">
            <MainLogo />

            <BackIcon />

            <RedPanel />

            <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
              <span>Chronic</span>
              <span className="text-[#DC3E46]">Illnesses</span>
            </p>

            <div className="flex-1 space-y-6">
              {Object.keys(illnesses).length === 0 ? (
                <p className="h-[200px] text-center text-[#0D122A] text-lg font-semibold my-6">
                  No Chronic Illnesses found.
                </p>
              ) : (
                Object.entries(illnesses).map(([id, illness]) => (
                  <ChronicIllnessCard
                    key={id}
                    value={id}
                    illness={illness}
                    setUpdateLoading={setUpdateLoading}
                    showRemoveModal={showRemoveModal}
                    setShowRemoveModal={setShowRemoveModal}
                    refreshIllnesses={fetchIllnesses} // Pass the refresh function
                  />
                ))
              )}
            </div>

            <button
              className="w-full px-5 py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg my-8" // Added mb-8 for extra space
              onClick={handleAddIllness}
            >
              + Add Chronic Illness
            </button>
          </div>
        )}

        {updateLoading && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default ChronicIllnesses;

import { createContext } from "react";

type SaveModalContextProps = {
  showSaveModal: boolean;
  setShowSaveModal: (showRemoveModal: boolean) => void;
  actionType: string;
  setActionType: (actionType: string) => void;
};

const SaveModalContext = createContext<SaveModalContextProps | undefined>(
  undefined
);

export default SaveModalContext;

import React, { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import useAmplify from "../../hooks/useAmplify";
import useToast from "../../hooks/useToast";
import ReactCodeInput from "react-code-input";
import { ValidatePassword } from "common/src/utility/validate/inputValidation";
import Logo from "common/public/icons/logo-header.png";
import RedBackIcon from "common/public/icons/red-back-icon.png";
import MessageIcon from "common/public/icons/message_light.png";
import PasswordIcon from "common/public/icons/password_light.png";
import LoadingAnimation from "../../components/LoadingAnimation";

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const auth = useAmplify();
  const { setNotify, setNotifyType } = useToast();

  const [pinCode, setPinCode] = useState<string>("");
  const [forgotPasswordStep, setForgotPasswordStep] = useState("initial");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [formValue, setFormValue] = useState({ password: "", newPassword: "" });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSendVerification = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    auth
      .resetPassword({ username: email })
      .then((res) => {
        setForgotPasswordStep("confirmation");
      })
      .catch((err) => {
        setNotifyType("danger");
        setNotify("Username or password is incorrect!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePinChange = (code: any) => {
    setPinCode(code);
  };

  const confirmPassword = async () => {
    setLoading(true);

    const isPasswordValid = ValidatePassword(formValue.newPassword);
    const isPinCorrect = pinCode && pinCode.length === 6;

    if (!isPasswordValid || !isPinCorrect) {
      setNotifyType("danger");
      setNotify(
        isPinCorrect
          ? "Invalid password"
          : "Please input a correct verification code."
      );
      setLoading(false);
      return;
    }

    auth
      .confirmResetPassword({
        username: email,
        confirmationCode: pinCode,
        newPassword: formValue.newPassword,
      })
      .then((res) => {
        setNotifyType("success");
        setNotify("Successfully changed, Please sign in with new password");
        history.push("/auth/sign-in");
      })
      .catch((err) => {
        console.log("Fetching data error:", err);
        setNotifyType("danger");
        setNotify(
          err?.toString().split(":")[1] ?? "There are some technical issues."
        );
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IonPage>
      <IonContent>
        <div
          className="flex flex-col w-full justify-between pb-16"
          style={{ height: `calc(100vh - 46px)` }}
        >
          <div className="flex justify-center items-center">
            <img className="my-6 w-[292px] h-[74px]" src={Logo} alt="Logo" />
          </div>

          <button
            className="flex items-center gap-2 text-[#DC3E46] cursor-pointer mb-6 px-6"
            onClick={() => history.goBack()}
          >
            <div className="w-7 h-7 bg-white rounded-full p-1">
              <img src={RedBackIcon} alt="Back" />
            </div>
            <span className="text-base">back</span>
          </button>

          {forgotPasswordStep === "initial" && (
            <form
              className="w-full flex flex-col px-5 grow"
              onSubmit={handleSendVerification}
            >
              <div className="grow">
                <p className="w-full text-center text-[44px] font-bold text-[#292941] mt-3 bg-ctgr-100">
                  Forgot <span className="text-[#DC3E46]">Password</span>
                </p>

                <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-6 gap-3">
                  <img src={MessageIcon} />

                  <input
                    className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                    placeholder="email@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    name="email"
                  />
                </div>
              </div>

              <button
                className="w-full mt-3 text-white font-semibold text-lg py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2"
                type="submit"
              >
                <span>Send a verification code</span>
              </button>
            </form>
          )}

          {forgotPasswordStep === "confirmation" && (
            <div className="w-full h-full flex flex-col items-center px-5">
              <form className="flex flex-col grow">
                <div className="grow">
                  <p className="w-full text-center text-[32px] font-bold text-[#0D122A] pt-3 mx-auto">
                    Confirmation Code
                  </p>

                  <p className="w-full text-center text-lg font-bold text-[#0D122A] pt-3 mx-auto">
                    The code has been sent to your email; please enter it below.
                  </p>

                  <div className="w-full mt-6 font-bold mx-auto text-center">
                    <ReactCodeInput
                      name="pin"
                      inputMode="numeric"
                      fields={6}
                      onChange={handlePinChange}
                      value={pinCode}
                    />
                  </div>

                  <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-8 gap-3">
                    <img src={PasswordIcon} />

                    <input
                      className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                      placeholder="New Password"
                      type="password"
                      onChange={onChange}
                      value={formValue.password}
                      name="password"
                    />
                  </div>

                  <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-8 gap-3">
                    <img src={PasswordIcon} />

                    <input
                      className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                      placeholder="Confirm Password"
                      type="password"
                      onChange={onChange}
                      value={formValue.newPassword}
                      name="newPassword"
                    />
                  </div>
                </div>
              </form>

              <button
                className="w-full mt-3 text-white font-semibold text-lg py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2"
                onClick={confirmPassword}
              >
                <span>Confirm</span>
              </button>
            </div>
          )}
        </div>

        {loading && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;

import { z } from "zod";

export const FormGeneralInformationSchema = z.object({
  first_name: z.string().nonempty("First name is required"),
  last_name: z.string().nonempty("Last name is required"),
  email: z.string().email(),
  profile_pic: z.string().optional().nullable(),
  address1: z.string().optional().nullable(),
  address2: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  zipcode: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),
  birth_date: z.string().optional().nullable(),
  gender: z.string().optional().nullable(),
  hair_color: z.string().optional().nullable(),
  eye_color: z.string().optional().nullable().nullable(),
  height: z.number().optional().optional().nullable(),
  weight: z.number().optional().optional().nullable(),
});

export const FormMedicalConditionSchema = z.object({
  alzheimers: z.boolean().optional(),
  blood_thinners: z.boolean().optional(),
  blood_type: z.string().optional(),
  dementia: z.boolean().optional(),
  diabetic_type1: z.boolean().optional(),
  diabetic_type2: z.boolean().optional(),
  epileptic: z.boolean().optional(),
  fall_risk: z.boolean().optional(),
  organ_donor: z.boolean().optional(),
  pacemaker: z.boolean().optional(),
  penicillin_allergy: z.boolean().optional(),
});

import React from "react";
import { ChronicIllnessProps } from "common/src/types";
import IllnessView from "./IllnessView";

interface DoctorViewListProps {
  illnesses: ChronicIllnessProps;
}

const IllnessViewList: React.FC<DoctorViewListProps> = ({ illnesses }) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-3 bg-ctgr-100">
        <span>Chronic</span>
        <span className="text-[#DC3E46]">Illnesses</span>
      </p>

      {illnesses &&
        Object.entries(illnesses).map(([id, illness]) => (
          <IllnessView key={id} illness={illness} />
        ))}
    </div>
  );
};

export default IllnessViewList;

import React, { useEffect, useState, useRef } from "react";

const CustomDropdown: React.FC<{
  options: string[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  isCountry?: boolean;
  countries?: any;
  flag?: string | null;
  isBorder?: boolean;
}> = ({
  options,
  value,
  onChange,
  placeholder,
  isCountry = false,
  countries,
  flag,
  isBorder = true,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative text-[#0D122A]" ref={dropdownRef}>
      <div
        className={`w-full bg-white rounded-lg outline-none text-base p-2 flex justify-between items-center cursor-pointer ${
          isBorder ? "border border-gray-300" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-start items-center gap-2">
          {flag && (
            <img
              src={`https://flagcdn.com/w20/${flag.toLowerCase()}.png`}
              alt={`flag`}
              className="w-5 h-3 object-cover"
            />
          )}

          <span>{value || placeholder}</span>
        </div>

        <i className={`arrow ${isOpen ? "up" : "down"}`}></i>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg max-h-60 overflow-y-auto">
          {isCountry ? (
            <div>
              <div
                className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 gap-2"
                onClick={() => {
                  onChange("");
                  setIsOpen(false);
                }}
              >
                <div>Select a country</div>
              </div>

              {countries.map((country: any) => (
                <div
                  key={country.isoCode}
                  className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100 gap-2"
                  onClick={() => {
                    onChange(country.name);
                    setIsOpen(false);
                  }}
                >
                  <img
                    src={`https://flagcdn.com/w20/${country.isoCode.toLowerCase()}.png`}
                    alt={`flag`}
                    className="w-5 h-3 object-cover"
                  />
                  <div>{country.name}</div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {options.map((option) => (
                <div
                  key={option}
                  className="px-3 py-2 cursor-pointer hover:bg-gray-100 gap-2"
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                  }}
                >
                  <div>{option}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;

import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import DoctorCard from "../../components/doctors/DoctorCard";
import LoadingAnimation from "../../components/LoadingAnimation";
import { getDoctors } from "common/src/utility/axioscalls";

const Doctors: React.FC = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await getDoctors();

        if (res.code === 200) {
          setDoctors(res.data.doctors);
        }
      } catch (err) {
        console.log();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchDoctor = async () => {
    try {
      const res = await getDoctors();

      setDoctors(res.data.doctors);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddDoctor = () => {
    history.push("/main/medical-data/doctors-add");
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className="px-5 pb-5 flex flex-col">
            <MainLogo />

            <BackIcon />

            <RedPanel />

            <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
              <span className="text-[#DC3E46]">Doctors</span>
            </p>

            {Object.keys(doctors).length === 0 ? (
              <p className="h-[200px] text-center text-[#0D122A] text-lg font-semibold my-6">
                No doctors found.
              </p>
            ) : (
              Object.entries(doctors).map(([id, doctor]) => (
                <DoctorCard
                  value={id}
                  doctor={doctor}
                  setUpdateLoading={setUpdateLoading}
                  showRemoveModal={showRemoveModal}
                  setShowRemoveModal={setShowRemoveModal}
                  refreshDoctors={fetchDoctor}
                />
              ))
            )}

            <button
              className="w-full px-5 py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg my-8" // Added mb-8 for extra space
              onClick={handleAddDoctor}
            >
              + Add Doctor
            </button>
          </div>
        )}

        {updateLoading && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default Doctors;

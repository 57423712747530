import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/useAuth";
import LoadingAnimation from "../../components/LoadingAnimation";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import { getHealthMilestones } from "common/src/utility/axioscalls";
import MileStoneCard from "../../components/health-milestones/MileStoneCard";

const HealthMilestone: React.FC = () => {
  const history = useHistory();
  const { firstName, lastName, avatar } = useAuth();

  const [loading, setLoading] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const fetchHealthMilestones = async () => {
    try {
      setLoading(true);

      const res = await getHealthMilestones();
      setMilestones(res.data.milestones);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHealthMilestones();
  }, []);

  const handleAddMilestone = () => {
    history.push("/main/medical-data/health-milestones-add");
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className="px-5 pb-5 flex flex-col">
            <MainLogo />

            <BackIcon />

            <RedPanel />

            <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
              <span>Health</span>
              <span className="text-[#DC3E46]">Milestones</span>
            </p>

            <div className="flex-1 space-y-6">
              {Object.keys(milestones).length === 0 ? (
                <p className="h-[200px] text-center text-[#0D122A] text-lg font-semibold my-6">
                  No milestones found.
                </p>
              ) : (
                Object.entries(milestones).map(([id, milestone]) => (
                  <MileStoneCard
                    key={id}
                    value={id}
                    milestone={milestone}
                    setUpdateLoading={setUpdateLoading}
                    showRemoveModal={showRemoveModal}
                    setShowRemoveModal={setShowRemoveModal}
                    refreshMilestones={fetchHealthMilestones} // Pass the refresh function
                  />
                ))
              )}
            </div>

            <button
              className="w-full px-5 py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg my-8" // Added mb-8 for extra space
              onClick={handleAddMilestone}
            >
              + Add Milestone
            </button>
          </div>
        )}

        {updateLoading && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default HealthMilestone;

import React, { useState } from "react";
import { IonContent, IonPage, IonCheckbox, IonItem } from "@ionic/react";
import MainHeader from "../../components/MainHeader";
import { useHistory } from "react-router";
import rightIcon from "common/public/icons/icon-right.png";

const OtherInformation: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonContent>
        <MainHeader />

        <div className="px-5">
          <p className="flex justify-start items-center gap-2 text-[#87827B] text-lg font-bold">
            <span onClick={() => history.goBack()} className="cursor-pointer">
              Dashboard
            </span>

            <img src={rightIcon} className="w-4 h-4" />

            <span>My Medical History</span>
          </p>

          <div className="text-[#0D122A] text-start text-[32px] font-bold my-6">
            Other Information
          </div>

          <div className="flex justify-start items-center gap-3 border-[#DDDBD9] border p-3 rounded-lg bg-[#EBEBEB]">
            <IonCheckbox
              labelPlacement="end"
              className="text-[#0D122A] text-base font-semibold border-none"
              color="success"
            />

            <p className="text-[#0D122A] text-base font-semibold">
              Show this on my VitaTrack page
            </p>
          </div>

          <p className="text-[#0D122A] text-base mt-6">Custom Text</p>

          <textarea
            rows={5}
            className="w-full text-[#0D122A] bg-white border border-[#DDDBD9] rounded-lg mt-3"
          />
        </div>

        <div className="mt-6 px-5 gap-3 flex mb-12">
          <div
            onClick={() => history.goBack()}
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
          >
            Cancel
          </div>
          <div className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white">
            Save
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OtherInformation;

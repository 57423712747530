import React, { useState } from "react";
import { IonContent, IonPage, IonCheckbox, IonItem } from "@ionic/react";
import MainHeader from "../../components/MainHeader";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useSaveModal from "../../hooks/useSaveModal";
import rightIcon from "common/public/icons/icon-right.png";

const SurgicalAndMilestoneHistoryAdd: React.FC = () => {
  const history = useHistory();
  const [date, setDate] = useState<Date | null>(null);
  const { showSaveModal, setShowSaveModal } = useSaveModal();

  const handleSaveSurgicalAndMilestoneHistory = () => {
    setShowSaveModal(true);
  };

  return (
    <IonPage>
      <IonContent>
        <MainHeader />

        <div className="px-5">
          <p className="flex justify-start flex-wrap items-center gap-2 text-[#87827B] text-lg font-bold">
            <span onClick={() => history.goBack()} className="cursor-pointer">
              Dashboard
            </span>

            <img src={rightIcon} className="w-4 h-4" />

            <span onClick={() => history.goBack()}>My Medical History</span>

            <img src={rightIcon} className="w-4 h-4" />

            <span>Surgical and Milestone History</span>
          </p>

          <div className="text-[#0D122A] text-start text-[32px] font-bold my-6">
            Add Event
          </div>

          <div className="mb-6 text-[#0D122A] text-base mt-6">
            <p className="font-semibold">Date</p>

            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)} // Update state on date change
              className="full-width border border-[#DDDBD9] p-2 mt-3 rounded-lg" // Added full-width class here
              dateFormat="yyyy/MM/dd" // Optional: specify the date format
              placeholderText="Select a date" // Placeholder text
            />
          </div>

          <div className="mb-6 text-[#0D122A] text-base">
            <p className="font-semibold mt-1">Event</p>

            <input
              name="event"
              className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg"
            />
          </div>

          <div className="mb-6 text-[#0D122A] text-base">
            <p className="font-semibold mt-1">Doctor</p>

            <input
              name="doctor"
              className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg"
            />
          </div>

          <div className="mb-6 text-[#0D122A] text-base">
            <p className="font-semibold mt-1">Outcome</p>

            <input
              name="outcome"
              className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg"
            />
          </div>

          <div className="mb-6 text-[#0D122A] text-base mt-6">
            <p className="font-semibold">Notes (optional)</p>

            <textarea
              rows={5}
              className="w-full bg-white border border-[#DDDBD9] rounded-lg my-6"
            />
          </div>
        </div>

        <div className="px-5 gap-3 flex mb-12">
          <div
            onClick={() => history.goBack()}
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-2"
            onClick={handleSaveSurgicalAndMilestoneHistory}
          >
            Save
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurgicalAndMilestoneHistoryAdd;

import Logo from "common/public/icons/logo-header.png";

const MainLogo: React.FC = () => {
  return (
    <div className="flex justify-center items-center my-6">
      <img className="w-[292px] h-[74px]" src={Logo} alt="Logo" />
    </div>
  );
};

export default MainLogo;

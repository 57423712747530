import React, { useState, useRef } from "react";
import { IonContent, IonPage, IonCheckbox, IonItem } from "@ionic/react";
import MainHeader from "../../components/MainHeader";
import { useHistory } from "react-router";
import useSaveModal from "../../hooks/useSaveModal";
import rightIcon from "common/public/icons/icon-right.png";

const InformationCardsUploadsAdd: React.FC = () => {
  const history = useHistory();
  const { showSaveModal, setShowSaveModal } = useSaveModal();

  const [fileName, setFileName] = useState<string | null>(null);
  const fileInput = useRef<any>(null);

  const handleSaveInformationCards = () => {
    setShowSaveModal(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const openFileUploader = () => {
    fileInput.current.click();
  };

  return (
    <IonPage>
      <IonContent>
        <MainHeader />

        <div className="px-5">
          <p className="flex justify-start flex-wrap items-center gap-2 text-[#87827B] text-lg font-bold">
            <span onClick={() => history.goBack()} className="cursor-pointer">
              Dashboard
            </span>

            <img src={rightIcon} className="w-4 h-4" />

            <span onClick={() => history.goBack()}>My Medical History</span>

            <img src={rightIcon} className="w-4 h-4" />

            <span>Information Cards Uploads</span>
          </p>

          <div className="text-[#0D122A] text-start text-[32px] font-bold my-6">
            Add Infromation Card
          </div>

          <div className="mb-6 text-[#0D122A] text-base">
            <p className="font-semibold mt-1">Upload Information Card Image</p>

            <div
              className="text-start rounded-lg font-semibold my-6 border border-[#DDDBD9] p-3"
              onClick={openFileUploader}
            >
              <input
                type="file"
                ref={fileInput}
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none", zIndex: -10 }}
              />
              Select File...
            </div>
          </div>
        </div>

        <div className="px-5 gap-3 flex mb-12">
          <div
            onClick={() => history.goBack()}
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-2"
            onClick={handleSaveInformationCards}
          >
            Save
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default InformationCardsUploadsAdd;

import { useState } from "react";
import { ChronicIllnessProps } from "common/src/types";
import trashIcon from "common/public/icons/icon-trash.png";
import { deleteIllnesses } from "common/src/utility/axioscalls";
import ItemRemoveModal from "../ItemRemoveModal";
import EditIcon from "common/public/icons/edit.png";
import ChronicIllnessViewModal from "./ChronicIllnessViewModal";
import ChronicIllnessEditModal from "./ChronicIllnessEditModal";

const ChronicIllnessCard: React.FC<{
  value: string;
  illness: ChronicIllnessProps;
  setUpdateLoading: (deleteLoading: boolean) => void;
  showRemoveModal: boolean;
  setShowRemoveModal: (showRemoveModal: boolean) => void;
  refreshIllnesses: () => void;
}> = ({
  value,
  illness,
  setUpdateLoading,
  showRemoveModal,
  setShowRemoveModal,
  refreshIllnesses,
}) => {
  const [showIllnessModal, setShowIllnessModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const handleOpenIllnessModal = () => {
    setShowIllnessModal(true);
  };

  const handleCloseIllnessModal = () => {
    setShowIllnessModal(false);
  };

  const handleRemoveIllness = () => {
    setShowRemoveModal(true);
  };

  const handleRemoveCancel = () => {
    setShowRemoveModal(false);
  };

  const handleUpdateCancel = () => {
    setShowUpdateModal(false);
  };

  const handleEditIllness = () => {
    setShowUpdateModal(true);
  };

  const handleDeleteDoctor = () => {
    setUpdateLoading(true);
    setShowRemoveModal(false);

    const fetchData = async () => {
      const data = {
        uuid: value,
      };

      try {
        await deleteIllnesses(data);

        refreshIllnesses();
      } catch (err) {
        console.log(err);
      } finally {
        setUpdateLoading(false);
      }
    };

    fetchData();
  };

  return (
    <div className="border border-[#DDDBD9] bg-white p-3 rounded-lg text-[#0D122A] text-base">
      <div onClick={handleOpenIllnessModal}>
        <p className="font-bold">{illness.name}</p>

        <p>{illness.diagnosis_date}</p>

        <p>{illness.notes}</p>
      </div>

      <div className="flex gap-3 justify-end -mt-4">
        <img src={EditIcon} onClick={handleEditIllness} />
        <img src={trashIcon} onClick={handleRemoveIllness} />
      </div>

      <ChronicIllnessViewModal
        illness={illness}
        showIllnessModal={showIllnessModal}
        handleCloseIllnessModal={handleCloseIllnessModal}
      />

      <ItemRemoveModal
        showRemoveModal={showRemoveModal}
        handleRemoveCancel={handleRemoveCancel}
        handleDeleteItem={handleDeleteDoctor}
      />

      <ChronicIllnessEditModal
        illness={illness}
        showUpdateModal={showUpdateModal}
        handleUpdateCancel={handleUpdateCancel}
        setUpdateLoading={setUpdateLoading}
        setShowUpdateModal={setShowUpdateModal}
        refreshIllnesses={refreshIllnesses}
        value={value}
      />
    </div>
  );
};

export default ChronicIllnessCard;

import { IonModal } from "@ionic/react";
import "./SaveModal.css";
import useSaveModal from "../hooks/useSaveModal";

const SaveModal: React.FC = () => {
  const { showSaveModal, setShowSaveModal, actionType, setActionType } =
    useSaveModal();

  const handleCancel = () => {
    setShowSaveModal(false);
  };

  const handleSave = () => {
    setShowSaveModal(false);
  };

  return (
    <IonModal
      isOpen={showSaveModal}
      id="custom-modal"
      onDidDismiss={handleCancel}
    >
      <div className="w-full p-6 bg-white">
        <div className="text-[#0D122A] text-[32px]">Save item?</div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleSave}
          >
            Save
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default SaveModal;

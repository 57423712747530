import React, { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import useAmplify from "../../hooks/useAmplify";
import useToast from "../../hooks/useToast";
import {
  ValidatePassword,
  ValidateEmail,
} from "common/src/utility/validate/inputValidation";
import ReactCodeInput from "react-code-input";
import Logo from "common/public/icons/logo-header.png";
import messageIcon from "common/public/icons/message_light.png";
import passwordIcon from "common/public/icons/password_light.png";
import profileIcon from "common/public/icons/profile.png";
import RedBackIcon from "common/public/icons/red-back-icon.png";
import LoadingAnimation from "../../components/LoadingAnimation";

const SignUp: React.FC = () => {
  const history = useHistory();
  const auth = useAmplify();

  const { setNotify, setNotifyType } = useToast();
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [signUpStep, setSignUpStep] = useState("initial");
  const [confirmationCode, setConfirmationCode] = useState<any>("");
  const [errorPasswordLength, setErrorPasswordLength] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    setErrorPasswordLength(false);

    if (!formValue.firstName) {
      setNotifyType("danger");
      setNotify("Invalid First Name");
      setLoading(false);
      return;
    }

    if (!formValue.lastName) {
      setNotifyType("danger");
      setNotify("Invalid Last Name");
      setLoading(false);
      return;
    }

    if (!ValidateEmail(formValue.email)) {
      setNotifyType("danger");
      setNotify("Invalid email!");
      setLoading(false);
      return;
    }

    if (!ValidatePassword(formValue.password)) {
      setNotifyType("danger");
      setNotify("Invalid password!");
      setErrorPasswordLength(true);
      setLoading(false);
      return;
    }

    if (formValue.password !== formValue.confirmPassword) {
      setNotifyType("danger");
      setNotify("Password doesn't match!");
      setLoading(false);
      return;
    }

    try {
      await auth.signUp({
        username: formValue.email,
        password: formValue.password,
        options: {
          userAttributes: {
            given_name: formValue.firstName,
            family_name: formValue.lastName,
          },
        },
      });

      setSignUpStep("confirmation");
    } catch (error: any) {
      if (error?.toString().split(":")[0] === "UsernameExistsException") {
        try {
          await auth.resendSignUpCode({
            username: formValue.email,
            options: {
              userAttributes: {
                email: formValue.email,
              },
            },
          });

          setSignUpStep("confirmation");
        } catch {
          console.log("Error resending confirmation code:", error);
          setNotifyType("danger");
          setNotify(
            error?.toString().split(":")[1] ??
              "There are some technical issues."
          );
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onConfirmationCodeChange = (event: any) => {
    setConfirmationCode(event);
  };

  const handleSendCodeAgain = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    auth
      .resendSignUpCode({ username: formValue.email })
      .then((res) => {
        setNotifyType("success");
        setNotify("Your code has been successfully sent again!");
      })
      .catch((err) => {
        setNotifyType("danger");
        setNotify(
          err?.toString().split(":")[1] ?? "There are some technical issues."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmSignUp = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    auth
      .confirmSignUp({
        username: formValue.email,
        confirmationCode: confirmationCode,
      })
      .then(() => {
        setNotifyType("success");
        setNotify("Your sign up is completed, please log in!");
        history.push("/auth/sign-in");
      })
      .catch((err) => {
        setNotifyType("danger");
        setNotify(
          err?.toString().split(":")[1] ?? "There are some technical issues."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IonPage>
      <IonContent>
        <div
          className="flex flex-col w-full justify-between pb-16"
          style={{ height: `calc(100vh - 46px)` }}
        >
          <div className="flex justify-center items-center">
            <img className="my-6 w-[292px] h-[74px]" src={Logo} alt="Logo" />
          </div>

          <button
            className="flex items-center gap-2 text-[#DC3E46] cursor-pointer mb-6 px-6"
            onClick={() => history.goBack()}
          >
            <div className="w-7 h-7 bg-white rounded-full p-1">
              <img src={RedBackIcon} alt="Back" />
            </div>
            <span className="text-base">back</span>
          </button>

          {signUpStep === "initial" && (
            <form
              className="w-full flex flex-col px-5 grow"
              onSubmit={handleSubmit}
            >
              <div className="grow">
                <p className="font-semibold text-lg text-[#313131] text-center leading-6">
                  Your medical history, whenever it's needed.
                </p>

                <p className="w-full text-center text-[44px] font-bold text-[#292941] mt-3 bg-ctgr-100">
                  Sign <span className="text-[#DC3E46]">Up</span>
                </p>

                <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                  <img src={profileIcon} className="w-7" />

                  <input
                    className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                    placeholder="First Name"
                    onChange={onChange}
                    value={formValue.firstName}
                    name="firstName"
                  />
                </div>

                <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                  <img src={profileIcon} className="w-7" />

                  <input
                    className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                    placeholder="Last Name"
                    onChange={onChange}
                    value={formValue.lastName}
                    name="lastName"
                  />
                </div>

                <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                  <img src={messageIcon} />

                  <input
                    className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                    placeholder="email@example.com"
                    onChange={onChange}
                    value={formValue.email}
                    name="email"
                  />
                </div>

                <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                  <img src={passwordIcon} />

                  <input
                    className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                    placeholder="******"
                    type="password"
                    onChange={onChange}
                    value={formValue.password}
                    name="password"
                  />
                </div>

                <div className="flex justify-start items-center bg-white border border-[#A6A7A8] rounded-full px-4 py-3 mt-3 gap-3">
                  <img src={passwordIcon} />

                  <input
                    className="w-full text-left bg-white rounded-lg text-[#0D122A] placeholder:text-[#A6A7A8] text-[18px] focus:outline-none"
                    placeholder="******"
                    type="password"
                    onChange={onChange}
                    value={formValue.confirmPassword}
                    name="confirmPassword"
                  />
                </div>

                {errorPasswordLength === true && (
                  <div className="text-[#E81D04] mt-3">
                    - Password must be at least 8 characters long.
                  </div>
                )}
              </div>

              <button
                className="w-full mt-3 text-white font-semibold text-lg py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2"
                type="submit"
              >
                <span>Sign Up</span>
              </button>
            </form>
          )}

          {signUpStep === "confirmation" && (
            <div className="w-full h-full flex flex-col px-5">
              <form
                onSubmit={confirmSignUp}
                className="h-full flex flex-col justify-between"
              >
                <div className="flex flex-col justify-between items-center">
                  <p className="w-full text-center text-xl font-bold text-[#0D122A] pt-3 mx-auto mt-12">
                    Confirmation Code
                  </p>

                  <p className="font-semibold text-lg text-[#313131] text-center leading-6">
                    The code has been sent to your email; please enter it below.
                  </p>

                  <div className="w-full mt-6 font-bold mx-auto text-center">
                    <ReactCodeInput
                      name="pin"
                      inputMode="numeric"
                      fields={6}
                      onChange={onConfirmationCodeChange}
                      value={confirmationCode}
                    />
                  </div>
                </div>

                <button
                  className="w-full mt-3 text-white font-semibold text-lg py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2"
                  type="submit"
                >
                  <span>Confirm</span>
                </button>
              </form>

              <button
                className="w-full flex items-center justify-center mt-6 text-sm font-bold rounded-lg"
                onClick={handleSendCodeAgain}
              >
                <span className="text-[#0D122A]">Resend the Code</span>
              </button>
            </div>
          )}
        </div>

        {loading === true && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default SignUp;

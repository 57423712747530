import React from "react";
import MedicationView from "./MedicationView";
import { MedicationProps } from "./MedicationCard";

interface MedicationViewListProps {
  medications: MedicationProps;
}

const MedicationViewList: React.FC<MedicationViewListProps> = ({
  medications,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="w-full text-center space-x-2 text-2xl font-bold text-[#DC3E46] my-3 bg-ctgr-100">
        <span>Medications</span>
      </p>

      {medications &&
        Object.entries(medications).map(([id, medication]) => (
          <MedicationView key={id} medication={medication} />
        ))}
    </div>
  );
};

export default MedicationViewList;

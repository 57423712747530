import React from "react";

interface InputProps {
  value: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  name: string;
  className: string;
  type?: string;
}

const Input: React.FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  name,
  className,
  type = "text",
}) => {
  return (
    <input
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      type={type}
      placeholder={placeholder}
      onFocus={(e) => (e.target.placeholder = "")}
      onBlur={(e) => (e.target.placeholder = placeholder)}
    />
  );
};

export default Input;

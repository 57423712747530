import { Redirect, Route } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Splash from "./pages/Splash/Splash";
import SignIn from "./pages/Auth/SignIn";
import SignUp from "./pages/Auth/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword";

import ToastNotify from "./components/ToastNotify";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
import "@ionic/react/css/palettes/dark.system.css";

/* Theme variables */
import "./theme/variables.css";
import MyLink from "./pages/Dashboard/MyLink";
import MedicalConditions from "./pages/MedicalHistory/MedicalConditions";
import MedicationList from "./pages/MedicalHistory/MedicationList";
import ChronicIllnesses from "./pages/MedicalHistory/ChronicIllnesses";
import SurgicalAndMilestoneHistory from "./pages/MedicalHistory/SurgicalAndMilestoneHistory";
import Doctors from "./pages/MedicalHistory/Doctors";
import InsuranceInformation from "./pages/MedicalHistory/InsuranceInformation";
import InformationCardsUploads from "./pages/MedicalHistory/InformationCardsUploads";
import OtherInformation from "./pages/MedicalHistory/OtherInformation";
import MedicationListAdd from "./pages/MedicalHistory/MedicationListAdd";
import ChronicIllnessesAdd from "./pages/MedicalHistory/ChronicIllnessesAdd";
import SurgicalAndMilestoneHistoryAdd from "./pages/MedicalHistory/SurgicalAndMilestoneHistoryAdd";
import DoctorsAdd from "./pages/MedicalHistory/DoctorsAdd";
import InsuranceInformationAdd from "./pages/MedicalHistory/InsuranceInformationAdd";
import InformationCardsUploadsAdd from "./pages/MedicalHistory/InformationCardsUploadsAdd";
import RemoveModal from "./components/RemoveModal";
import SaveModal from "./components/SaveModal";
import MedicalView from "./pages/Dashboard/MedicalView";
import GeneralInformation from "./pages/MedicalHistory/GeneralInformation";
import TermsOfService from "./pages/MedicalHistory/TermsOfService";
import HealthMilestone from "./pages/MedicalHistory/HealthMilestone";
import HealthMilestoneAdd from "./pages/MedicalHistory/HealthMilestoneAdd";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Route exact path="/">
        <Redirect to="/splash" />
      </Route>
      <Route path="/splash" exact render={() => <Splash />} />
      <Route path="/auth/sign-in" exact render={() => <SignIn />} />
      <Route path="/auth/sign-up" exact render={() => <SignUp />} />
      <Route
        path="/auth/forgot-password"
        exact
        render={() => <ForgotPassword />}
      />
      <Route path="/main/my-link" exact render={() => <MyLink />} />
      <Route path="/main/open-view" exact render={() => <MedicalView />} />
      <Route
        path="/main/medical-data/personal-information"
        exact
        render={() => <GeneralInformation />}
      />
      <Route
        path="/main/medical-data/medical-conditions"
        exact
        render={() => <MedicalConditions />}
      />
      <Route
        path="/main/medical-data/medication-list"
        exact
        render={() => <MedicationList />}
      />
      <Route
        path="/main/medical-data/medication-add"
        exact
        render={() => <MedicationListAdd />}
      />
      <Route
        path="/main/medical-data/health-milestones"
        exact
        render={() => <HealthMilestone />}
      />
      <Route
        path="/main/medical-data/health-milestones-add"
        exact
        render={() => <HealthMilestoneAdd />}
      />
      <Route
        path="/main/medical-data/chronic-illnesses"
        exact
        render={() => <ChronicIllnesses />}
      />
      <Route
        path="/main/medical-data/chronic-illnesses-add"
        exact
        render={() => <ChronicIllnessesAdd />}
      />
      <Route
        path="/main/medical-data/surgical-and-milestone-history"
        exact
        render={() => <SurgicalAndMilestoneHistory />}
      />
      <Route
        path="/main/medical-data/surgical-and-milestone-history-add"
        exact
        render={() => <SurgicalAndMilestoneHistoryAdd />}
      />
      <Route
        path="/main/medical-data/doctors"
        exact
        render={() => <Doctors />}
      />
      <Route
        path="/main/medical-data/doctors-add"
        exact
        render={() => <DoctorsAdd />}
      />
      <Route
        path="/main/medical-data/insurance-information"
        exact
        render={() => <InsuranceInformation />}
      />
      <Route
        path="/main/medical-data/insurance-information-add"
        exact
        render={() => <InsuranceInformationAdd />}
      />
      <Route
        path="/main/medical-data/information-cards-uploads"
        exact
        render={() => <InformationCardsUploads />}
      />
      <Route
        path="/main/medical-data/information-cards-uploads-add"
        exact
        render={() => <InformationCardsUploadsAdd />}
      />
      <Route
        path="/main/medical-data/other-information"
        exact
        render={() => <OtherInformation />}
      />
      <Route
        path="/main/medical-data/terms-of-service"
        exact
        render={() => <TermsOfService />}
      />
      <ToastNotify />
      <RemoveModal />
      <SaveModal />
    </IonReactRouter>
  </IonApp>
);

export default App;

import React from "react";
import RedBackIcon from "common/public/icons/red-back-icon.png";
import { useHistory } from "react-router";

const BackIcon: React.FC = () => {
  const history = useHistory();

  return (
    <button
      className="flex items-center gap-2 text-[#DC3E46] cursor-pointer mb-6"
      onClick={() => history.goBack()}
    >
      <div className="w-7 h-7 bg-white rounded-full p-1">
        <img src={RedBackIcon} alt="Back" />
      </div>
      <span className="text-base">back</span>
    </button>
  );
};

export default BackIcon;

import React from "react";
import {
  AVATAR_URL_PREFIX,
  AVATAR_URL_SUFFIX,
} from "../../../common/src/utility/const";
import { useAuth } from "../hooks/useAuth";

const RedPanel: React.FC = () => {
  const { firstName, lastName, avatar } = useAuth();

  return (
    <div className="bg-[#DD1A04] rounded-[10px] flex p-2">
      <div className="rounded-full w-14">
        <div className="w-14 h-14 rounded-full overflow-hidden">
          {avatar && (
            <img
              src={AVATAR_URL_PREFIX + avatar + AVATAR_URL_SUFFIX}
              alt={`${firstName} ${lastName}`}
              className="w-full h-full object-cover p-1 rounded-full bg-white"
            />
          )}
        </div>
      </div>

      <div className="px-6 flex items-center text-[25px] text-white">
        {firstName} {lastName}
      </div>
    </div>
  );
};

export default RedPanel;

import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonCheckbox,
  IonButton,
  IonText,
} from "@ionic/react";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";

const TermsOfService: React.FC = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    // Handle acceptance logic here
    console.log("Terms accepted");
  };

  return (
    <IonPage>
      <IonContent>
        <div className="px-5 text-[#0D122A]">
          <MainLogo />

          <BackIcon />

          <h1 className="leading-7">Terms of Service</h1>

          <IonText className="leading-7">
            <p>
              Welcome to our service. By using this application, you agree to
              the following terms:
            </p>
            <ol>
              <li>You agree to provide accurate and complete information.</li>
              <li>
                You are responsible for maintaining the confidentiality of your
                account.
              </li>
              <li>
                We reserve the right to modify or terminate the service for any
                reason, without notice, at any time.
              </li>
              <li>
                All content provided on this application is for informational
                purposes only.
              </li>
            </ol>
          </IonText>

          <div className="flex items-center mt-4">
            <IonCheckbox
              checked={accepted}
              color="success"
              className="text-[#0D122A] text-base font-semibold border-none"
              onIonChange={(e: any) => setAccepted(e.detail.checked)}
            />
            <IonText className="ml-2">
              I have read and agree to the Terms of Service
            </IonText>
          </div>
        </div>

        <div className="px-5 gap-3 flex my-4">
          <button className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-2 text-white flex justify-center items-center">
            Accept Terms
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermsOfService;

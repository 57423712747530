import React, { useState } from "react";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import "react-datepicker/dist/react-datepicker.css";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import { useAuth } from "../../hooks/useAuth";
import useToast from "../../hooks/useToast";
import { useHistory } from "react-router";
import { ChronicIllnessProps } from "common/src/types";
import Input from "../../components/Input";
import { addIllnesses } from "common/src/utility/axioscalls";

const ChronicIllnessesAdd: React.FC = () => {
  const { firstName, lastName, avatar } = useAuth();
  const { setNotify, setNotifyType } = useToast();
  const history = useHistory();

  const [saveLoading, setSaveLoading] = useState(false);
  const [illnessesForm, setIllnessesForm] = useState<ChronicIllnessProps>({
    diagnosis_date: "",
    name: "",
    notes: "",
  });

  const validateIllnessesForm = () => {
    const { diagnosis_date, name, notes } = illnessesForm;
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    if (name.trim() === "") return { valid: false, field: "Name" };
    if (diagnosis_date.trim() === "")
      return { valid: false, field: "Diagnosis Date" };
    if (!datePattern.test(diagnosis_date.trim()))
      return { valid: false, field: "Diagnosis Date Format" };

    return { valid: true };
  };

  const handleAddIllness = async () => {
    const validation = validateIllnessesForm();

    if (!validation.valid) {
      setNotifyType("danger");
      if (validation.field === "Diagnosis Date Format") {
        setNotify("Please enter the Diagnosis Date in the format MM/DD/YYYY.");
      } else {
        setNotify(`Please fill in the required field: ${validation.field}.`);
      }
      return;
    }

    setSaveLoading(true);

    try {
      await addIllnesses(illnessesForm);

      history.push("/main/medical-data/chronic-illnesses");
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIllnessesForm({ ...illnessesForm, [e.target.name]: e.target.value });
  };

  return (
    <IonPage>
      <IonContent>
        <div className="px-5">
          <MainLogo />

          <BackIcon />

          <RedPanel />

          <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
            <span>Add</span>
            <span className="text-[#DC3E46]">Illness</span>
          </p>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">Name</p>

            <Input
              name="name"
              value={illnessesForm.name}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Diagnosis Date
            </p>

            <Input
              name="diagnosis_date"
              value={illnessesForm.diagnosis_date}
              onChange={onChange}
              placeholder="01/01/2023"
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Notes (optional)
            </p>

            <textarea
              name="notes"
              value={illnessesForm.notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
              }
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
              rows={3}
            />
          </div>
        </div>

        <div className="px-5">
          <div
            className="w-full px-5 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-4 text-white text-lg my-4"
            onClick={handleAddIllness}
          >
            {saveLoading ? (
              <IonSpinner
                name="crescent"
                color="light"
                style={{ fontSize: "30px" }}
              />
            ) : (
              <span>Save</span>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChronicIllnessesAdd;

import React from "react";
import { DoctorProps } from "common/src/types";
import { IonModal } from "@ionic/react";

export const DoctorViewModal: React.FC<{
  doctor: DoctorProps;
  showDoctorModal: boolean;
  handleCloseDoctorModal: () => void;
}> = ({ doctor, showDoctorModal, handleCloseDoctorModal }) => {
  return (
    <IonModal
      isOpen={showDoctorModal}
      id="custom-modal"
      onDidDismiss={handleCloseDoctorModal}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#292941]">
        <p className="w-full text-center space-x-2 text-2xl font-bold my-3 bg-ctgr-100">
          <span>Doctor</span>
          <span className="text-[#DC3E46]">Details</span>
        </p>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Name:</p>
          <p>{doctor?.name}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Address:</p>
          <p>{doctor?.address}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Email:</p>
          <p>{doctor?.email}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Specialty:</p>
          <p>{doctor?.specialty}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Practice:</p>
          <p>{doctor?.practice}</p>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={handleCloseDoctorModal}
            className="w-fit px-3 py-2 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg z-10"
          >
            Close
          </button>
        </div>
      </div>
    </IonModal>
  );
};

export default DoctorViewModal;

import { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import TripleDotIcon from "common/public/triple-dot.png";
import RedLogout from "common/public/icons/logout.png";
import ChronicIllnessIcon from "common/public/icons/Chronic-Illness.png";
import ManageIDsIcon from "common/public/icons/Manage-IDs.png";
import ManageSharingIcon from "common/public/icons/Manage-Sharing.png";
import useToast from "../hooks/useToast";
import { useHistory } from "react-router";
import useAmplify from "../hooks/useAmplify";
import { IonToggle } from "@ionic/react";
import { useAuth } from "../hooks/useAuth";

interface MainDropDownProps {
  setLoading: (loading: boolean) => void;
}

const MainDropDown: React.FC<MainDropDownProps> = ({ setLoading }) => {
  const auth = useAmplify();
  const history = useHistory();
  const { setNotifyType, setNotify } = useToast();
  const [isToggled, setIsToggled] = useState(true);
  const { setIsLogIn } = useAuth();

  const handleToggle = (event: CustomEvent) => {
    setIsToggled(event.detail.checked);
  };

  const handleSignOut = async () => {
    setLoading(true);

    try {
      await auth.signOut();
      setNotifyType("success");
      setNotify("You’ve successfully logged out!");
      history.push("/auth/sign-in");
      setIsLogIn(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton>
          <div className="bg-[#DD1A04] w-9 h-9 rounded-full">
            <img src={TripleDotIcon} />
          </div>
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 text-[#625E5E] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <div className="flex px-4 py-4 gap-1 items-center">
            <img src={ManageIDsIcon} className="w-[22px] h-[22px]" />
            <div className="grow">Enable Profile</div>

            <IonToggle
              checked={isToggled}
              onIonChange={handleToggle}
              className={
                isToggled ? "toggle-on small-toggle" : "toggle-off small-toggle"
              }
            />
          </div>
        </div>

        <MenuItem>
          <div className="py-1 items-center">
            <div className="flex px-4 py-2 gap-1">
              <img src={ManageIDsIcon} className="w-[22px] h-[22px]" />
              <div>Manage IDs</div>
            </div>
          </div>
        </MenuItem>

        <MenuItem>
          <div className="py-1 items-center">
            <div className="flex px-4 py-2 gap-1">
              <img src={ManageSharingIcon} className="w-[22px] h-[22px]" />
              <div>Manage Sharing</div>
            </div>
          </div>
        </MenuItem>

        <MenuItem>
          <div className="py-1 items-center">
            <div className="flex px-4 py-2 gap-1">
              <img src={ChronicIllnessIcon} className="w-[22px] h-[22px]" />
              <div>Chronic Illness</div>
            </div>
          </div>
        </MenuItem>

        <MenuItem>
          <div className="py-1 items-center" onClick={handleSignOut}>
            <div className="flex px-4 py-4 gap-1">
              <img src={RedLogout} className="w-[22px] h-[22px]" />
              <div>Log out</div>
            </div>
          </div>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};

export default MainDropDown;

import { useContext } from "react";
import ToastContext from "../../../mobile/src/providers/ToastContext";

/**
 * Custom hook for accessing the Toast context.
 *
 * @returns {Object} The Toast context object.
 * @throws {Error} If used outside of a NotifyToastProvider.
 */
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a NotifyToastProvider");
  }
  return context;
};

export default useToast;

// Import React and the QRCode component
import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeGenerator: React.FC<{ weBid: string }> = ({ weBid }) => {
  return (
    <div className="flex justify-center my-6">
      <QRCodeCanvas value={weBid} size={78} />
    </div>
  );
};

export default QRCodeGenerator;

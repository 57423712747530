import { useState, ReactNode } from "react";
import ToastContext from "./ToastContext";

type NotifyToastProviderProps = {
  children: ReactNode;
};

const ToastProvider = ({ children }: NotifyToastProviderProps) => {
  const [toastFlag, setToastFlag] = useState(false);
  const [text, setText] = useState("");
  const [notifyType, setNotifyType] = useState("");

  const toggleModal = () => {
    setToastFlag((prevToastFlag) => !prevToastFlag);
  };

  const setNotify = (message: string) => {
    setText(message);
    toggleModal();
  };

  return (
    <ToastContext.Provider
      value={{
        toastFlag,
        toggleModal,
        setNotify,
        text,
        notifyType,
        setNotifyType,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;

import React, { useEffect } from "react";
import logoHeader from "common/public/icons/logo-header.png";
import questionIcon from "common/public/icons/question-icon.png";

const MainHeader: React.FC = () => {
  return (
    <div className="flex justify-between px-5 py-3">
      <img src={logoHeader} className="w-[192px] h-[44px]" />

      <div className="flex justify-start items-center gap-1">
        <img src={questionIcon} className="w-[14px] h-[14px]" />

        <p className="text-[#E81D04] text-base font-semibold">How it works</p>
      </div>
    </div>
  );
};

export default MainHeader;

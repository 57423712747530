import React from "react";
import { IonModal } from "@ionic/react";
import { MilestoneProps } from "common/src/types";

export const MilestoneViewModal: React.FC<{
  milestone: MilestoneProps;
  showMilestoneModal: boolean;
  handleCloseMilestoneModal: () => void;
}> = ({ milestone, showMilestoneModal, handleCloseMilestoneModal }) => {
  return (
    <IonModal
      isOpen={showMilestoneModal}
      id="custom-modal"
      onDidDismiss={handleCloseMilestoneModal}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#0D122A]">
        <p className="w-full text-center space-x-2 text-2xl font-bold my-3 bg-ctgr-100">
          <span>Milestone</span>
          <span className="text-[#DC3E46]">Details</span>
        </p>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Doctor:</p>
          <p>{milestone?.doctor}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Event:</p>
          <p>{milestone?.event}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Outcome:</p>
          <p>{milestone?.outcome}</p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-semibold">Start Date:</p>
          <p>{milestone?.start_date}</p>
        </div>

        {milestone?.notes && (
          <div className="flex items-center gap-2">
            <p className="text-lg font-semibold">Notes (optional):</p>
            <p>{milestone?.notes}</p>
          </div>
        )}

        <div className="flex justify-end mt-6">
          <button
            onClick={handleCloseMilestoneModal}
            className="w-fit px-3 py-2 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg z-10"
          >
            Close
          </button>
        </div>
      </div>
    </IonModal>
  );
};

export default MilestoneViewModal;

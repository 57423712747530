import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import { getMedications } from "common/src/utility/axioscalls";
import RedPanel from "../../components/RedPanel";
import { useAuth } from "../../hooks/useAuth";
import MedicationCard from "../../components/medications/MedicationCard";
import LoadingAnimation from "../../components/LoadingAnimation";

const MedicationList: React.FC = () => {
  const history = useHistory();

  const [medications, setMedications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const fetchMedications = async () => {
    try {
      setLoading(true);

      const res = await getMedications();
      setMedications(res.data.medications);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMedications();
  }, []);

  const handleAddMedication = () => {
    history.push("/main/medical-data/medication-add");
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <LoadingAnimation />
        ) : (
          <div className="px-5 pb-5 flex flex-col">
            <MainLogo />

            <BackIcon />

            <RedPanel />

            <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
              <span className="text-[#DC3E46]">Medications</span>
            </p>

            <div className="flex-1 space-y-6">
              {Object.keys(medications).length === 0 ? (
                <p className="h-[200px] text-center text-[#0D122A] text-lg font-semibold my-6">
                  No medications found.
                </p>
              ) : (
                Object.entries(medications).map(([id, medication]) => (
                  <MedicationCard
                    key={id}
                    value={id}
                    medication={medication}
                    setUpdateLoading={setUpdateLoading}
                    showRemoveModal={showRemoveModal}
                    setShowRemoveModal={setShowRemoveModal}
                    refreshMedications={fetchMedications} // Pass the refresh function
                  />
                ))
              )}
            </div>

            <button
              className="w-full px-5 py-4 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center text-white text-lg my-8" // Added mb-8 for extra space
              onClick={handleAddMedication}
            >
              + Add Medication
            </button>
          </div>
        )}

        {updateLoading && <LoadingAnimation />}
      </IonContent>
    </IonPage>
  );
};

export default MedicationList;

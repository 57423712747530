import React, { useState } from "react";
import TrashIcon from "common/public/icons/icon-trash.png";
import EditIcon from "common/public/icons/edit.png";
import DoctorViewModal from "./DoctorViewModal";
import ItemRemoveModal from "../ItemRemoveModal";
import DoctorEditModal from "./DoctorEditModal";
import { deleteDoctor } from "common/src/utility/axioscalls";
import { DoctorProps } from "common/src/types";

const DoctorCard: React.FC<{
  value: string;
  doctor: DoctorProps;
  setUpdateLoading: (deleteLoading: boolean) => void;
  showRemoveModal: boolean;
  setShowRemoveModal: (showRemoveModal: boolean) => void;
  refreshDoctors: () => void; // New prop for refreshing medications
}> = ({
  value,
  doctor,
  setUpdateLoading,
  showRemoveModal,
  setShowRemoveModal,
  refreshDoctors,
}) => {
  const [showDoctorModal, setShowDoctorModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const handleOpenDoctorModal = () => {
    setShowDoctorModal(true);
  };

  const handleCloseDoctorModal = () => {
    setShowDoctorModal(false);
  };

  const handleEditDoctor = () => {
    setShowUpdateModal(true);
  };

  const handleRemoveCancel = () => {
    setShowRemoveModal(false);
  };

  const handleRemoveDoctor = () => {
    setShowRemoveModal(true);
  };

  const handleUpdateCancel = () => {
    setShowUpdateModal(false);
  };

  const handleDeleteDoctor = () => {
    setUpdateLoading(true);
    setShowRemoveModal(false);

    const fetchData = async () => {
      const data = {
        uuid: value,
      };

      try {
        await deleteDoctor(data);

        refreshDoctors();
      } catch (err) {
        console.log(err);
      } finally {
        setUpdateLoading(false);
      }
    };

    fetchData();
  };

  return (
    <div className="border border-[#DDDBD9] bg-white p-3 rounded-lg text-[#0D122A] text-base">
      <div onClick={handleOpenDoctorModal}>
        <p className="text-base font-bold">
          {doctor.name} - {doctor.specialty}
        </p>

        <p className="font-semibold mt-1">{doctor.address}</p>

        <p className="font-semibold">{doctor.phone}</p>

        <p className="font-semibold">{doctor.email}</p>
      </div>

      <div className="flex gap-3 justify-end -mt-4">
        <img src={EditIcon} onClick={handleEditDoctor} />
        <img src={TrashIcon} onClick={handleRemoveDoctor} />
      </div>

      <DoctorViewModal
        doctor={doctor}
        showDoctorModal={showDoctorModal}
        handleCloseDoctorModal={handleCloseDoctorModal}
      />

      <ItemRemoveModal
        showRemoveModal={showRemoveModal}
        handleRemoveCancel={handleRemoveCancel}
        handleDeleteItem={handleDeleteDoctor}
      />

      <DoctorEditModal
        doctor={doctor}
        showUpdateModal={showUpdateModal}
        handleUpdateCancel={handleUpdateCancel}
        setUpdateLoading={setUpdateLoading}
        setShowUpdateModal={setShowUpdateModal}
        refreshDoctors={refreshDoctors}
        value={value}
      />
    </div>
  );
};

export default DoctorCard;

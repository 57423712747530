import { useState, ReactNode } from "react";
import SaveModalContext from "./SaveModalContext";

type SaveModalProviderProps = {
  children: ReactNode;
};

const SaveModalProvider = ({ children }: SaveModalProviderProps) => {
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [actionType, setActionType] = useState("");

  return (
    <SaveModalContext.Provider
      value={{
        showSaveModal,
        setShowSaveModal,
        actionType,
        setActionType,
      }}
    >
      {children}
    </SaveModalContext.Provider>
  );
};

export default SaveModalProvider;

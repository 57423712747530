import { useContext } from "react";
import AuthContext from "../../../mobile/src/providers/AuthContext";

/**
 * Custom hook for accessing the authentication context.
 *
 * @returns {Object} The authentication context object.
 * @throws {Error} If used outside of an AuthProvider.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("userAuthContext must be used within a userProvider");
  }
  return context;
};

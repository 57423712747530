import { ChronicIllnessProps } from "common/src/types";

const IllnessView: React.FC<{ illness: ChronicIllnessProps }> = ({
  illness,
}) => {
  const { name, diagnosis_date, notes } = illness;

  return (
    <div className="w-full text-[#0D122A] flex flex-col gap-6">
      <p className="text-base mt-1">{name}</p>
      <p className="text-base">{diagnosis_date}</p>
      <p className="text-base">{notes}</p>
    </div>
  );
};

export default IllnessView;

import React, { useState } from "react";
import { IonModal, IonCheckbox } from "@ionic/react";
import Input from "../Input";
import CustomDropdown from "../CustomDropDown";
import { MedicationProps } from "./MedicationCard";
import { updateMedication } from "common/src/utility/axioscalls";

export const MedicationEditModal: React.FC<{
  medication: MedicationProps;
  showUpdateModal: boolean;
  handleUpdateCancel: () => void;
  setUpdateLoading: (updateLoading: boolean) => void;
  setShowUpdateModal: (showUpdateModal: boolean) => void;
  refreshMedications: () => void;
  value: string;
}> = ({
  medication,
  showUpdateModal,
  handleUpdateCancel,
  setUpdateLoading,
  setShowUpdateModal,
  refreshMedications,
  value,
}) => {
  const [medicationForm, setMedicationForm] =
    useState<MedicationProps>(medication);

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: any } }
  ) => {
    const { name, value } = e.target;
    if (name === "dosage") {
      setMedicationForm({
        ...medicationForm,
        [name]: parseInt(value),
      });
    } else if (name === "active") {
      setMedicationForm({
        ...medicationForm,
        [name]: value,
      });
    } else {
      setMedicationForm({ ...medicationForm, [name]: value });
    }
  };

  const handleUpdateMedication = () => {
    setShowUpdateModal(true);

    const fetchData = async () => {
      try {
        const updatedMedicationForm = {
          ...medicationForm,
          dosage: parseInt(medicationForm.dosage.toString()),
        };

        await updateMedication({ id: value, ...updatedMedicationForm });

        refreshMedications();
      } catch (err) {
        console.log(err);
      } finally {
        setShowUpdateModal(false);
      }
    };

    fetchData();
  };

  return (
    <IonModal
      isOpen={showUpdateModal}
      id="custom-modal"
      onDidDismiss={handleUpdateCancel}
    >
      <div className="w-full h-fit max-h-[90vh] p-6 bg-white flex flex-col gap-3 overflow-y-auto text-[#0D122A]">
        <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
          <span>Edit</span>
          <span className="text-[#DC3E46]">Medication</span>
        </p>

        <div>
          <p className=" text-base font-semibold mt-1">Medication</p>

          <Input
            name="medication_name"
            value={medicationForm.medication_name}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Dosage</p>

          <Input
            name="dosage"
            value={medicationForm.dosage}
            onChange={onChange}
            placeholder=""
            type="number"
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold mb-2">Measurement</p>

          <CustomDropdown
            options={[
              "Milligrams (mg)",
              "Grams (g)",
              "Micrograms (mcg)",
              "Milliliters (mL)",
              "Liters (L)",
              "Units (U)",
              "Percent (%)",
              "Milliequivalents (mEq)",
              "Drops (gtt)",
            ]}
            value={medicationForm.measurement || ""}
            onChange={(value) =>
              onChange({
                target: { name: "measurement", value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
            placeholder="Select a Measurement"
          />
        </div>

        <div>
          <p className=" text-base font-semibold mb-2">Frequency</p>

          <CustomDropdown
            options={[
              "Once daily",
              "Twice daily",
              "Three times daily",
              "Every 4 hours",
              "Every 6 hours",
              "As needed",
              "Once a week",
              "Every month",
            ]}
            value={medicationForm.frequency || ""}
            onChange={(value) =>
              onChange({
                target: { name: "frequency", value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
            placeholder="Select a Frequency"
          />
        </div>

        <div>
          <p className=" text-base font-semibold mb-2">Form</p>

          <CustomDropdown
            options={[
              "Tablet",
              "Capsule",
              "Liquid",
              "Injectable",
              "Topical",
              "Patch",
              "Spray",
              "Inhaler",
              "Lozenge",
              "Suppository",
              "Granule",
              "Film",
            ]}
            value={medicationForm.form || ""}
            onChange={(value) =>
              onChange({
                target: { name: "form", value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
            placeholder="Select a Form"
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Doctor</p>

          <Input
            name="doctor"
            value={medicationForm.doctor}
            onChange={onChange}
            placeholder=""
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
          />
        </div>

        <div>
          <p className=" text-base font-semibold">Notes (optional)</p>

          <textarea
            name="notes"
            value={medicationForm.notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            }
            className="w-full border border-[#DDDBD9] bg-white mt-2 p-2 rounded-lg "
            rows={3}
          />
        </div>

        <div className="flex justify-start items-center mt-2 gap-2">
          <p className=" text-base font-semibold">Taking currently</p>

          <IonCheckbox
            labelPlacement="end"
            className=" text-base border border-gray-300"
            color="success"
            name="active"
            checked={medicationForm.active}
            onIonChange={(e: any) =>
              onChange({
                target: { name: "active", value: e.detail.checked },
              })
            }
          />
        </div>

        <div className="flex gap-3 mt-8">
          <div
            className="flex-1 rounded-lg border border-[#0D122A77] text-[#0D122A] text-center py-2"
            onClick={handleUpdateCancel}
          >
            Cancel
          </div>
          <div
            className="flex-1 rounded-lg bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center  py-2 text-white"
            onClick={handleUpdateMedication}
          >
            Update
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default MedicationEditModal;

/**
 * @file MyLink.tsx
 * @description This component represents the My Link page in the dashboard.
 * It displays user information, medical profile options, and edit profile options.
 * @module MyLink
 */

import React, { useState, useEffect } from "react";
import { IonPage, IonContent } from "@ionic/react";
import { useHistory } from "react-router";

import RedShare from "common/public/icons/red-share.png";
import RedCopy from "common/public/icons/red-copy.png";
import RedPreview from "common/public/icons/red-view.png";

import HandIcon from "common/public/icons/hand.png";
import MainDropDown from "../../components/MainDropDown";
import RedPanel from "../../components/RedPanel";

import GeneralInformationIcon from "common/public/icons/General-Information.png";
import MedicalConditionIcon from "common/public/icons/Medical-Condition.png";
import MedicationListIcon from "common/public/icons/Medication-List.png";
import ChronicIllnessIcon from "common/public/icons/Chronic-Illness.png";
import ManageIDsIcon from "common/public/icons/Manage-IDs.png";
import ManageSharingIcon from "common/public/icons/Manage-Sharing.png";
import TermOfServiceIcon from "common/public/icons/Term-of-Service.png";
import LoadingAnimation from "../../components/LoadingAnimation";
import MainLogo from "../../components/MainLogo";
import { useAuth } from "../../hooks/useAuth";
import { getWeBid } from "common/src/utility/axioscalls";
import useToast from "../../hooks/useToast";
import QRCodeGenerator from "../../components/QRCodeGenerator";

/**
 * MyLink component
 * @returns {JSX.Element} The rendered MyLink component
 */
const MyLink: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { firstName, lastName, avatar, isLogIn, uuid } = useAuth();
  const [weBid, setWeBid] = useState("");
  const { setNotify, setNotifyType } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getWeBid();
        setWeBid(res.data.web_id);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const handleCopy = () => {
    navigator.clipboard
      .writeText("https://vtrack.me/" + weBid)
      .then(() => {
        setNotifyType("success");
        setNotify("Medical profile page copied!");
      })
      .catch((err) => {
        console.error("Failed to copy Web ID: ", err);
      });
  };

  /**
   * Handles navigation to the General Information page
   */
  const handleMoveGeneralInformation = () => {
    history.push("/main/medical-data/personal-information");
  };

  const handleMoveMedicalCondition = () => {
    history.push("/main/medical-data/medical-conditions");
  };

  const handleMoveMedicationList = () => {
    history.push("/main/medical-data/medication-list");
  };

  const handleMoveHealthMilestones = () => {
    history.push("/main/medical-data/health-milestones");
  };

  const handleMoveIllnesses = () => {
    history.push("/main/medical-data/chronic-illnesses");
  };

  const handleMoveChronicIllness = () => {
    history.push("/main/medical-data/chronic-illnesses");
  };

  const handleDoctors = () => {
    history.push("/main/medical-data/doctors");
  };

  const handleMoveTermsOfService = () => {
    history.push("/main/medical-data/terms-of-service");
  };

  return (
    <IonPage>
      <IonContent>
        {loading === true ||
        firstName === "" ||
        lastName === "" ||
        isLogIn === false ? (
          <LoadingAnimation />
        ) : (
          <div className="h-full flex flex-col">
            <div className="px-5">
              <MainLogo />

              <RedPanel />

              <div className="mt-6 px-2 flex justify-between items-center">
                <div>
                  <div className="flex items-center">
                    <h2 className="text-xl font-semibold text-black">
                      Hello, {firstName}
                    </h2>
                    <img src={HandIcon} alt="Hand icon" className="ml-2" />
                  </div>
                  <p className="text-base text-gray-500">
                    How do you feel today?
                  </p>
                </div>

                <MainDropDown setLoading={setLoading} />
              </div>
            </div>

            <div className="px-5 bg-[#F8F8F8] rounded-t-[40px] grow">
              <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] mt-6 bg-ctgr-100">
                <span>Medical</span>
                <span className="text-[#DC3E46]">Profile</span>
              </p>

              <QRCodeGenerator weBid={weBid} />

              <div className="flex gap-3 rounded-[10px]">
                <div
                  className="flex-1 flex py-2 gap-[6px] cursor-pointer text-[#F62004] bg-white border border-[#EFEFEF] rounded-[20px]"
                  onClick={() => history.push("/main/open-view")}
                >
                  <div className="flex mx-auto gap-1 items-center">
                    <img src={RedPreview} className="mx-auto w-[16px]" />
                    <p className="text-center font-semibold">Preview</p>
                  </div>
                </div>

                <div className="flex-1 flex py-2 gap-[6px] cursor-pointer text-[#F62004] bg-white border border-[#EFEFEF] rounded-[20px]">
                  <div className="flex mx-auto gap-1 items-center">
                    <img src={RedShare} className="mx-auto w-[14px]" />
                    <p className="text-center font-semibold">Share</p>
                  </div>
                </div>

                <div
                  className="flex-1 flex py-2 gap-[6px] cursor-pointer text-[#F62004] bg-white border border-[#EFEFEF] rounded-[20px]"
                  onClick={handleCopy}
                >
                  <div className="flex mx-auto gap-1 items-center">
                    <img src={RedCopy} className="mx-auto w-[14px] h-[14px]" />
                    <p className="text-center font-semibold">Copy</p>
                  </div>
                </div>
              </div>

              <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
                <span>Edit</span>
                <span className="text-[#DC3E46]">Profile</span>
              </p>

              <div className="grid grid-cols-1 mb-4 overscroll-auto gap-[10px] text-[#292941]">
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveGeneralInformation}
                >
                  <img src={GeneralInformationIcon} className="w-8 h-8 mx-2" />
                  <div>General Information</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveMedicalCondition}
                >
                  <img src={MedicalConditionIcon} className="w-8 h-8 mx-2" />
                  <div>Essential Details</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveMedicationList}
                >
                  <img src={MedicationListIcon} className="w-8 h-8 mx-2" />
                  <div>Medications</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleDoctors}
                >
                  <img src={ChronicIllnessIcon} className="w-8 h-8 mx-2" />
                  <div>Doctors</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveHealthMilestones}
                >
                  <img src={ChronicIllnessIcon} className="w-8 h-8 mx-2" />
                  <div>Health Milestones</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveIllnesses}
                >
                  <img src={ChronicIllnessIcon} className="w-8 h-8 mx-2" />
                  <div>Chronic Illnesses</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveChronicIllness}
                >
                  <img src={ChronicIllnessIcon} className="w-8 h-8 mx-2" />
                  <div>Persistent Health Issues</div>
                </div>
                <div className="flex items-center bg-white py-4 cursor-pointer">
                  <img src={ManageIDsIcon} className="w-8 h-8 mx-2" />
                  <div>Manage IDs</div>
                </div>
                <div className="flex items-center bg-white py-4 cursor-pointer">
                  <img src={ManageSharingIcon} className="w-8 h-8 mx-2" />
                  <div>Other Account Access</div>
                </div>
                <div
                  className="flex items-center bg-white py-4 cursor-pointer"
                  onClick={handleMoveTermsOfService}
                >
                  <img src={TermOfServiceIcon} className="w-8 h-8 mx-2" />
                  <div>Terms of Service</div>
                </div>
              </div>
            </div>

            <div className="text-gray-500 p-7 space-y-2 text-center">
              <p>Copyright &copy; 2024 VitaTrack</p>
              <p>Support: support@vitatrack.health</p>
              <p>{uuid}</p>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MyLink;

/**
 * @file Splash.tsx
 * @description This component represents the splash screen of the application.
 * It displays a logo and automatically navigates to the appropriate page based on the user's authentication status.
 */

import React, { useEffect } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import splashIcon from "common/public/icons/logo-header.png";
import { getUserInfo } from "common/src/utility/axioscalls";

/**
 * Splash component
 * @returns {JSX.Element} The rendered Splash component
 */
const Splash: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    /**
     * Checks user authentication and redirects accordingly
     */
    const checkAuthAndRedirect = async (): Promise<void> => {
      const user_info = await getUserInfo();

      if (user_info) {
        history.push("/main/my-link");
      } else {
        history.push("/auth/sign-in");
      }
    };

    const timer = setTimeout(checkAuthAndRedirect, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [history]);

  return (
    <IonPage>
      <IonContent>
        <div className="h-full flex flex-col gap-4 -mt-20 justify-center items-center">
          <img src={splashIcon} className="w-[292px] h-[74px]" alt="Logo" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Splash;

import { getAxiosInstance } from ".";

export const getIllnesses = async () => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.get(`/v1/activity/illnesses`);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const updateIllnesses = async (data: any) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.post(`/v1/activity/illnesses`, data);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const addIllnesses = async (data: any) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.put(`/v1/activity/illnesses`, data);
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

export const deleteIllnesses = async (data: any) => {
  let res;
  try {
    const axiosInstance = await getAxiosInstance();
    const instance = await axiosInstance();

    if (!instance) {
      throw new Error("Failed to create an Axios instance");
    }

    res = await instance.delete(`/v1/activity/illnesses`, { data });
  } catch (error) {
    console.log("An error occurred:", error);
  }
  return res ? res.data : "";
};

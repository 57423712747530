/**
 * @file MedicalView.tsx
 * @description This component represents the Medical View page in the dashboard.
 * It displays user's general information, important conditions, medications, and chronic illnesses.
 * @module MedicalView
 */

import React, { useEffect, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import download from "common/public/icons/download.png";
import { getUserAll } from "common/src/utility/axioscalls/allInformation";
import LoadingAnimation from "../../components/LoadingAnimation";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import MainLogo from "../../components/MainLogo";
import PersonalInformationView from "../../components/PersonalInformationView";
import ImportantConditionsView from "../../components/ImportantConditionsView";
import MedicationViewList from "../../components/medications/MedicationViewList";
import DoctorViewList from "../../components/doctors/DoctorViewList";
import MilestoneViewList from "../../components/health-milestones/MilestoneViewList";
import IllnessViewList from "../../components/chronic-illness/IllnessViewList";
import { MedicationProps } from "../../components/medications/MedicationCard";
import { ChronicIllnessProps, DoctorProps } from "common/src/types";
import { MilestoneProps } from "common/src/types";

/**
 * Interface for general information properties
 * @interface generalInformationProps
 */
interface generalInformationProps {
  address1: string;
  address2: string;
  profile_pic: string;
  birth_date: string;
  city: string;
  country: string;
  eye_color: string;
  first_name: string;
  gender: string;
  hair_color: string;
  height: string;
  last_name: string;
  phone: string;
  state: string;
  weight: string;
  zipcode: string;
}

interface medicalConditionProps {
  alzheimers: boolean;
  dementia: boolean;
  diabetic1: boolean;
  diabetic2: boolean;
  bloodThinners: boolean;
  epileptic: boolean;
  fallRisk: boolean;
  organDonor: boolean;
  pacemaker: boolean;
  penicillinAllergy: boolean;
  blood_type: boolean;
}

/**
 * MedicalView component
 * @returns {JSX.Element} The rendered MedicalView component
 */
const MedicalView: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [generalInformation, setGeneralInformation] =
    useState<generalInformationProps>({
      address1: "",
      address2: "",
      profile_pic: "",
      birth_date: "",
      city: "",
      country: "",
      eye_color: "",
      first_name: "",
      gender: "",
      hair_color: "",
      height: "",
      last_name: "",
      phone: "",
      state: "",
      weight: "",
      zipcode: "",
    });

  const [medicalConditions, setMedicalConditions] =
    useState<medicalConditionProps>({
      alzheimers: false,
      dementia: false,
      diabetic1: false,
      diabetic2: false,
      bloodThinners: false,
      epileptic: false,
      fallRisk: false,
      organDonor: false,
      pacemaker: false,
      penicillinAllergy: false,
      blood_type: false,
    });

  const [medications, setMedications] = useState<MedicationProps>({
    active: true,
    doctor: "",
    dosage: 0,
    form: "",
    frequency: "",
    measurement: "",
    medication_name: "",
    notes: "",
  });

  const [doctors, setDoctors] = useState<DoctorProps>({
    name: "",
    specialty: "",
    address: "",
    phone: "",
    email: "",
    practice: "",
  });

  const [milestones, setMilestones] = useState<MilestoneProps>({
    doctor: "",
    event: "",
    notes: "",
    outcome: "",
    start_date: "",
  });

  const [illnesses, setIllnesses] = useState<ChronicIllnessProps>({
    name: "",
    diagnosis_date: "",
    notes: "",
  });

  const isEmptyObject = (obj: object): boolean => Object.keys(obj).length === 0;

  useEffect(() => {
    /**
     * Fetches user data from the server
     */

    const fetchData = async () => {
      setLoading(true);

      const res = await getUserAll();
      const {
        general,
        major_medical,
        medications,
        doctors,
        milestones,
        illnesses,
      } = res.data;

      if (res.code === 200) {
        setGeneralInformation(general);
        setMedicalConditions(major_medical);
        setMedications(medications);
        setDoctors(doctors);
        setMilestones(milestones);
        setIllnesses(illnesses);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <IonPage>
      <IonContent>
        {loading === true ? (
          <LoadingAnimation />
        ) : (
          <div className="h-h-46 flex flex-col text-[#0D122A] px-5">
            <MainLogo />

            <BackIcon />

            <RedPanel />

            <div className="grow h-full">
              {!isEmptyObject(generalInformation) && (
                <div className="mt-6">
                  <PersonalInformationView
                    generalInformation={generalInformation}
                  />
                </div>
              )}

              {!isEmptyObject(medicalConditions) && (
                <div className="mt-6">
                  <ImportantConditionsView condition={medicalConditions} />
                </div>
              )}

              {!isEmptyObject(medications) && (
                <div className="mt-6">
                  <MedicationViewList medications={medications} />
                </div>
              )}

              {!isEmptyObject(doctors) && (
                <div className="mt-6">
                  <p className="w-full text-center space-x-2 text-2xl font-bold text-[#DC3E46] my-3 bg-ctgr-100">
                    <span>Doctors</span>
                  </p>

                  <DoctorViewList doctors={doctors} />
                </div>
              )}

              {!isEmptyObject(milestones) && (
                <div className="mt-6">
                  <MilestoneViewList milestones={milestones} />
                </div>
              )}

              {!isEmptyObject(illnesses) && (
                <div className="mt-6">
                  <IllnessViewList illnesses={illnesses} />
                </div>
              )}

              <div className="w-fit mx-auto flex items-center justify-center gap-1 border border-[#DDDBD9] my-7 px-4 py-2 text-sm rounded-lg font-medium">
                <img src={download} /> Download PDF
              </div>

              <div className="text-[#BAB6B3] text-sm pb-12 gap-4">
                © Copyright World Health Organization (WHO), 2021. All Rights
                Reserved. The information in the various pages of the WHO web
                site is issued by the World Health Organization for general
                distribution. The information presented is protected under the
                Berne Convention for the Protection of Literature and Artistic
                works, under other international conventions and under national
                laws on copyright and neighbouring rights. Extracts of the
                information in the web site may be reviewed, reproduced or
                translated for research or private study but not for sale or for
                use in conjunction with commercial purposes.
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MedicalView;

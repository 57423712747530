import React, { useState } from "react";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import MainLogo from "../../components/MainLogo";
import BackIcon from "../../components/BackIcon";
import RedPanel from "../../components/RedPanel";
import { DoctorProps } from "common/src/types";
import Input from "../../components/Input";
import useToast from "../../hooks/useToast";
import { useHistory } from "react-router";
import { addDoctor } from "common/src/utility/axioscalls";

const DoctorsAdd: React.FC = () => {
  const { setNotify, setNotifyType } = useToast();
  const history = useHistory();

  const [saveLoading, setSaveLoading] = useState(false);
  const [doctorForm, setDoctorForm] = useState<DoctorProps>({
    address: "",
    email: "",
    name: "",
    phone: "",
    practice: "",
    specialty: "",
  });

  const validateDoctorForm = () => {
    const { address, email, name, phone, practice, specialty } = doctorForm;
    if (address.trim() === "") return { valid: false, field: "Address" };

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) return { valid: false, field: "Email" };

    if (name.trim() === "") return { valid: false, field: "Name" };
    if (practice.trim() === "") return { valid: false, field: "Practice" };
    if (specialty.trim() === "") return { valid: false, field: "Specialty" };

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) return { valid: false, field: "Phone" };

    return { valid: true };
  };

  const handleAddDoctor = async () => {
    const validation = validateDoctorForm();

    if (!validation.valid) {
      setNotifyType("danger");
      if (validation.field === "Email") {
        setNotify(`Please enter a valid email address.`);
      } else if (validation.field === "Phone") {
        setNotify(`Please enter a valid phone number.`);
      } else {
        setNotify(`Please fill in the required field : ${validation.field}.`);
      }
      return;
    }

    setSaveLoading(true);

    try {
      await addDoctor(doctorForm);

      history.push("/main/medical-data/doctors");
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDoctorForm({ ...doctorForm, [e.target.name]: e.target.value });
  };
  return (
    <IonPage>
      <IonContent>
        <div className="px-5">
          <MainLogo />

          <BackIcon />

          <RedPanel />

          <p className="w-full text-center space-x-2 text-2xl font-bold text-[#292941] my-6 bg-ctgr-100">
            <span>Add</span>
            <span className="text-[#DC3E46]">Doctor</span>
          </p>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Full Name
            </p>

            <Input
              name="name"
              value={doctorForm.name}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">Email</p>

            <Input
              name="email"
              value={doctorForm.email}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Specialty
            </p>

            <Input
              name="specialty"
              value={doctorForm.specialty}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Address
            </p>

            <Input
              name="address"
              value={doctorForm.address}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">Phone</p>

            <Input
              name="phone"
              value={doctorForm.phone}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-[#0D122A] text-base font-semibold mt-1">
              Practice
            </p>

            <Input
              name="practice"
              value={doctorForm.practice}
              onChange={onChange}
              placeholder=""
              className="w-full border border-[#DDDBD9] bg-white p-2 rounded-lg text-[#0D122A]"
            />
          </div>
        </div>

        <div className="px-5">
          <div
            className="w-full px-5 rounded-full bg-gradient-to-r from-custom-red-1 to-custom-red-2 text-center py-4 text-white text-lg my-4"
            onClick={handleAddDoctor}
          >
            {saveLoading ? (
              <IonSpinner
                name="crescent"
                color="light"
                style={{ fontSize: "30px" }}
              />
            ) : (
              <span>Save</span>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DoctorsAdd;
